import { useWallet } from '../../hooks/useWallet'

import AddressSection from './AddressSection'

function RecipientSettings() {
  const { address } = useWallet()
  return (
    <AddressSection
      field="recipient"
      title="Recipient"
      label="Who will receive the output tokens?"
      defaultAddress={address as string}
      defaultLabel={`${address?.slice(0, 6)}...${address?.slice(-4)} (You)`}
    />
  )
}

export default RecipientSettings
