import { useQuery } from '@tanstack/react-query'

import API from '../api'
import { HooksMessage } from '../types/hooks'

import { useWallet } from './useWallet'

const fetchAccount = async () => {
  const response = await API.getAccount()
  const account = response.data
  return {
    ...account,
    message: account.message ? (JSON.parse(account.message) as HooksMessage) : undefined,
  }
}

function useAccount() {
  const { address } = useWallet()

  return useQuery({
    queryKey: ['account', address],
    queryFn: () => fetchAccount(),
    enabled: !!address,
  })
}

export default useAccount
