import { useState } from 'react'

import API from '../../api'
import Button from '../Common/Button'
import NetworkDropdown from '../General/NetworkDropdown'

import ErrorResponse from './ErrorResponse'
import FrequencyDropdown from './FrequencyDropdown'
import SuccessResponse from './SuccessResponse'

type Network = {
  id: number
  name: string
}

function BlockTrade() {
  const [network, setNetwork] = useState<Network>({ id: 42161, name: 'ARBITRUM' })
  const [tokenFrom, setTokenFrom] = useState('')
  const [amountFrom, setAmountFrom] = useState<number | ''>('')
  const [tokenTo, setTokenTo] = useState('')
  const [frequency, setFrequency] = useState('')
  const [interval, setInterval] = useState<number | ''>(1)
  const [error, setError] = useState<string | null>(null)
  const [statusMessage, setStatusMessage] = useState<React.ReactNode>(null)

  const handleStart = async () => {
    if (!network || !tokenFrom || amountFrom === '' || !tokenTo || !frequency || interval === '') {
      setError('Please complete all fields before starting.')
      return
    }

    setError(null)
    const description = `Buy ${tokenTo} with ${amountFrom} ${tokenFrom} on ${network.name}`

    try {
      await API.postAccountTask({
        description: description,
        frequency: frequency,
        interval: Number(interval),
      })
      setStatusMessage(<SuccessResponse />)
    } catch (error) {
      console.error('Error creating task:', error)
      setStatusMessage(<ErrorResponse />)
    }
  }

  return (
    <div className="w-full h-full pt-20 md:pt-0 p-4">
      <div className="space-y-4 p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-whiteFrost">Block Trade</h2>
        {statusMessage && (
          <button onClick={() => setStatusMessage(null)} className="text-whiteFrost font-visbyBold px-2">
            x
          </button>
        )}
      </div>
      <div className="flex-grow flex items-center justify-center">
        {statusMessage ? (
          <>{statusMessage}</>
        ) : (
          <form className="w-full p-6 bg-backgroundSurface rounded-xl space-y-6">
            <NetworkDropdown defaultNetwork={network} onSelect={(selectedNetwork) => setNetwork(selectedNetwork)} />
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="tokenFrom" className="text-whiteFrost text-sm font-medium mb-1">
                  Token from
                </label>
                <input
                  type="text"
                  id="tokenFrom"
                  value={tokenFrom}
                  onChange={(e) => setTokenFrom(e.target.value)}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="amountFrom" className="text-whiteFrost text-sm font-medium mb-1">
                  Amount from
                </label>
                <input
                  type="number"
                  id="amountFrom"
                  value={amountFrom}
                  onChange={(e) => setAmountFrom(Number(e.target.value))}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                  min={0}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="tokenTo" className="text-whiteFrost text-sm font-medium mb-1">
                  Token to
                </label>
                <input
                  type="text"
                  id="tokenTo"
                  value={tokenTo}
                  onChange={(e) => setTokenTo(e.target.value)}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
              <div className="flex-1" />
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <FrequencyDropdown onSelect={(selectedFrequency) => setFrequency(selectedFrequency)} />
              </div>
              <div className="flex-1">
                <label htmlFor="interval" className="text-whiteFrost text-sm font-medium mb-1">
                  Interval
                </label>
                <input
                  type="number"
                  id="interval"
                  value={interval}
                  onChange={(e) => setInterval(Number(e.target.value))}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                  min={1}
                />
              </div>
            </div>

            {error && <p className="text-red-500 text-sm text-center">{error}</p>}

            <Button type="button" onClick={handleStart} className="w-full">
              Start
            </Button>
          </form>
        )}
      </div>
    </div>
  )
}

export default BlockTrade
