import { CircleAlert } from 'lucide-react'

interface Props {
  message: string
}

export default function ErrorMessage({ message }: Props) {
  return (
    <div className="flex items-center space-x-2 text-red-500">
      <CircleAlert className="w-5 h-5" />
      <span>{message}</span>
    </div>
  )
}
