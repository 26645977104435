import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAddress } from 'viem'
import { z } from 'zod'

import { ListType } from '../types/hooks'

const Address = z.string().refine((value) => isAddress(value, { strict: false }), 'Must be a valid address')

const AccountSettingsValidator = z
  .object({
    recipient: Address.optional(),
    signer: Address.optional(),
    slippage: z.number().min(0, 'Slippage cannot be negative').max(50, 'Slippage cannot exceed 50%').optional(),
    expiration: z.number().int().min(1, 'Expiration must be at least 1 minute').optional(),
    tokensIn: z
      .object({
        tokens: z.array(Address),
        type: z.nativeEnum(ListType).optional(),
      })
      .optional(),
    destinationChains: z
      .object({
        chains: z.array(z.number().int()),
        type: z.nativeEnum(ListType).optional(),
      })
      .optional(),
  })
  .transform((data) => ({
    ...data,
    tokensIn: data.tokensIn?.tokens.length === 0 && data.tokensIn.type === undefined ? undefined : data.tokensIn,
    destinationChains:
      data.destinationChains?.chains.length === 0 && data.destinationChains.type === undefined
        ? undefined
        : data.destinationChains,
  }))
  .refine(
    (data) => {
      const slippageDefined = data.slippage !== undefined
      const expirationUndefined = data.expiration === undefined
      return !(slippageDefined && expirationUndefined)
    },
    {
      path: ['expiration'],
      message: 'Must define expiration',
    }
  )
  .refine(
    (data) => {
      const expirationDefined = data.expiration !== undefined
      const slippageUndefined = data.slippage === undefined
      return !(expirationDefined && slippageUndefined)
    },
    {
      path: ['slippage'],
      message: 'Must define slippage',
    }
  )
  .refine(
    (data) => {
      const tokensInUndefined = data.tokensIn === undefined
      const tokensInTypeDefined = data.tokensIn?.type !== undefined
      const tokensInListEmpty = data.tokensIn?.tokens.length === 0
      return !(!tokensInUndefined && tokensInTypeDefined && tokensInListEmpty)
    },
    {
      path: ['tokensIn.tokens'],
      message: 'Must select at least one token',
    }
  )
  .refine(
    (data) => {
      const tokensInUndefined = data.tokensIn === undefined
      const tokensInTypeUndefined = data.tokensIn?.type === undefined
      const tokensInListNotEmpty = data.tokensIn?.tokens.length !== 0
      return !(!tokensInUndefined && tokensInTypeUndefined && tokensInListNotEmpty)
    },
    {
      path: ['tokensIn.type'],
      message: 'Must select a list type',
    }
  )

  .refine(
    (data) => {
      const destinationChainsUndefined = data.destinationChains === undefined
      const destinationChainsTypeDefined = data.destinationChains?.type !== undefined
      const destinationChainsListEmpty = data.destinationChains?.chains.length === 0
      return !(!destinationChainsUndefined && destinationChainsTypeDefined && destinationChainsListEmpty)
    },
    {
      path: ['destinationChains.chains'],
      message: 'Must select at least one chain',
    }
  )
  .refine(
    (data) => {
      const destinationChainsUndefined = data.destinationChains === undefined
      const destinationChainsTypeUndefined = data.destinationChains?.type === undefined
      const destinationChainsListNotEmpty = data.destinationChains?.chains.length !== 0
      return !(!destinationChainsUndefined && destinationChainsTypeUndefined && destinationChainsListNotEmpty)
    },
    {
      path: ['destinationChains.type'],
      message: 'Must select a list type',
    }
  )

export type AccountSettings = z.infer<typeof AccountSettingsValidator>

function useSettingsForm() {
  return useForm<AccountSettings>({
    resolver: zodResolver(AccountSettingsValidator),
  })
}

export { AccountSettingsValidator }
export default useSettingsForm
