import { useState } from 'react'

import API from '../../api'
import Button from '../Common/Button'
import NetworkDropdown from '../General/NetworkDropdown'

import ErrorResponse from './ErrorResponse'
import FrequencyDropdown from './FrequencyDropdown'
import ProtocolDropdown from './ProtocolDropdown'
import SuccessResponse from './SuccessResponse'

type Network = {
  id: number
  name: string
}

const AutoExit = () => {
  const [network, setNetwork] = useState<Network>({ id: 10, name: 'OPTIMISM' })
  const [protocol, setProtocol] = useState('')
  const [token, setToken] = useState('')
  const [percentage, setPercentage] = useState<number | ''>('')
  const [frequency, setFrequency] = useState('')
  const [interval, setInterval] = useState<number | ''>(1)
  const [error, setError] = useState<string | null>(null)
  const [statusMessage, setStatusMessage] = useState<React.ReactNode>(null)

  const handleStart = async () => {
    if (!network || !protocol || !token || percentage === '' || !frequency || interval === '') {
      setError('Please complete all fields before starting.')
      return
    }
    setError(null)

    const description = `Withdraw ${percentage}% of all my ${token} from ${protocol} on ${network.name}`

    try {
      await API.postAccountTask({
        description: description,
        frequency: frequency,
        interval: Number(interval),
      })
      setStatusMessage(<SuccessResponse />)
    } catch (error) {
      console.error('Error creating task:', error)
      setStatusMessage(<ErrorResponse />)
    }
  }

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = Number(e.target.value)
    if (value >= 0 && value <= 100) {
      setPercentage(value)
    }
  }

  return (
    <div className="w-full h-full pt-20 md:pt-0 p-4">
      <div className="space-y-4 p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-whiteFrost">Auto Exit</h2>
        {statusMessage && (
          <button onClick={() => setStatusMessage(null)} className="text-whiteFrost font-visbyBold px-2">
            x
          </button>
        )}
      </div>
      <div className="flex-grow flex items-center justify-center">
        {statusMessage ? (
          <>{statusMessage}</>
        ) : (
          <form className="w-full p-6 bg-backgroundSurface rounded-xl space-y-6">
            <NetworkDropdown defaultNetwork={network} onSelect={(selectedNetwork) => setNetwork(selectedNetwork)} />
            <div className="flex flex-col">
              <ProtocolDropdown onSelect={(selectedProtocol) => setProtocol(selectedProtocol)} />
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="token" className="text-whiteFrost text-sm font-medium mb-1">
                  Token
                </label>
                <input
                  type="text"
                  id="token"
                  value={token}
                  onChange={(e) => setToken(e.target.value)}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="percentage" className="text-whiteFrost text-sm font-medium mb-1">
                  Percentage
                </label>
                <div className="relative">
                  <input
                    type="number"
                    id="percentage"
                    value={percentage}
                    onChange={handlePercentageChange}
                    className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full pr-10"
                    min={0}
                    max={100}
                  />
                  <span className="absolute right-3 top-3 text-whiteFrost">%</span>
                </div>
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <FrequencyDropdown onSelect={(selectedFrequency) => setFrequency(selectedFrequency)} />
              </div>
              <div className="flex-1">
                <label htmlFor="interval" className="text-whiteFrost text-sm font-medium mb-1">
                  Interval
                </label>
                <input
                  type="number"
                  id="interval"
                  value={interval}
                  onChange={(e) => setInterval(Number(e.target.value))}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                  min={1}
                />
              </div>
            </div>

            {error && <p className="text-red-500 text-sm text-center">{error}</p>}

            <Button type="button" onClick={handleStart} className="w-full">
              Start
            </Button>
          </form>
        )}
      </div>
    </div>
  )
}

export default AutoExit
