import { useAccount, useDisconnect, useSignMessage, useSignTypedData } from 'wagmi'

import { useAuth } from './useAuth'

export const useWallet = () => {
  const { address, isConnected, chain, isConnecting } = useAccount()
  const { signTypedDataAsync, isPending: isTypedPending } = useSignTypedData()
  const { signMessageAsync, isPending: isSigningPending } = useSignMessage()
  const { disconnect } = useDisconnect()
  const { isAuthenticated, isAuthenticating } = useAuth()

  return {
    address: isAuthenticated && chain ? address : undefined,
    chainId: chain?.id,
    isConnected: isConnected && isAuthenticated,
    isConnecting: isConnecting || isAuthenticating,
    signTypedDataAsync,
    signMessageAsync,
    isPending: isTypedPending || isSigningPending,
    disconnect,
  }
}
