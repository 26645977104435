import { useQuery } from '@tanstack/react-query'

import API from '../api'

const fetchIntents = async (taskId: string) => {
  const response = await API.getIntents(taskId)
  console.log(response)
  return response.data
}

function useIntents(taskId: string) {
  return useQuery({
    queryKey: ['intents', taskId],
    queryFn: () => fetchIntents(taskId),
  })
}

export default useIntents
