import { useQuery } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

const fetchTokens = async (chain: number) => {
  const response = await API.getAccountTokens(chain)
  const tokens = response.data
  return tokens
}

function useTokens() {
  const { address, chainId } = useWallet()

  return useQuery({
    queryKey: ['tokens', chainId],
    queryFn: () => fetchTokens(chainId!),
    enabled: !!address,
  })
}

export default useTokens
