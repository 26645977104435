import { arrayify, concat, defaultAbiCoder, hexlify } from 'ethers/lib/utils'
import { isAddress } from 'viem'

import { Domain, Hook, Hooks, HooksMessage, ListType, Mode, Types } from '../types/hooks'

export function formatDate(timestamp: number): string {
  const date = new Date(timestamp)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
  })
  const formattedTime = date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })

  return `${formattedDate} ${formattedTime}`
}

export function computePredicateId(validator: string): string {
  const addressBytes = arrayify(validator)
  const paddedAddressBytes = concat([addressBytes, new Uint8Array(32 - addressBytes.length)])
  return hexlify(paddedAddressBytes)
}

export function buildDelegationHook(validator: string, delegate: string): Hook {
  const predicateId = computePredicateId(validator)
  const encodedModeConfig = defaultAbiCoder.encode(['address'], [delegate])
  const encodedHookConfig = defaultAbiCoder.encode(['uint8', 'bytes'], [Mode.Signer, encodedModeConfig])
  return { id: predicateId, config: encodedHookConfig }
}

export function buildMinAmountOutHook(validator: string, slippage: bigint, expiration: number, signer: string): Hook {
  const MIN_SLIPPAGE = BigInt(0)
  const MAX_SLIPPAGE = BigInt(5e17)
  if (slippage < MIN_SLIPPAGE || slippage > MAX_SLIPPAGE) throw new Error('Slippage must be between 0 and 50%')

  const predicateId = computePredicateId(validator)
  const encodedModeConfig = defaultAbiCoder.encode(['uint256', 'uint256', 'address'], [slippage, expiration, signer])
  const encodedHookConfig = defaultAbiCoder.encode(['uint8', 'bytes'], [Mode.MinAmountsOut, encodedModeConfig])
  return { id: predicateId, config: encodedHookConfig }
}

export function buildHooksMessage(settler: string, init: Hook[]): HooksMessage {
  if (!isAddress(settler, { strict: false })) throw new Error('Invalid settler address')
  if (init.length == 0) throw new Error('Cannot sign empty safeguards')

  const hooks: Hooks = { init, pre: [], post: [], end: [] }
  const domain: Domain = {
    name: 'Mimic',
    version: '1',
    verifyingContract: settler as `0x${string}`,
  }
  const types: Types = {
    Hooks: [
      { name: 'init', type: 'Hook[]' },
      { name: 'pre', type: 'Hook[]' },
      { name: 'post', type: 'Hook[]' },
      { name: 'end', type: 'Hook[]' },
    ],
    Hook: [
      { name: 'id', type: 'bytes32' },
      { name: 'config', type: 'bytes' },
    ],
  }

  return { domain, types, hooks }
}

function extractValidatorFromHookId(hookId: string): string {
  const idBytes = arrayify(hookId)
  const validatorBytes = idBytes.slice(0, 20)
  return hexlify(validatorBytes)
}

function decodeHookConfig<P extends readonly unknown[]>(
  hook: Hook,
  expectedMode: Mode,
  parameterTypes: readonly string[]
): P {
  const [mode, encodedModeConfig] = defaultAbiCoder.decode(['uint8', 'bytes'], hook.config)

  if (mode !== expectedMode) {
    throw new Error(`Invalid mode for hook. Expected: ${expectedMode}, Received: ${mode}`)
  }

  return defaultAbiCoder.decode(parameterTypes, encodedModeConfig) as P
}

function decodeDelegationHook(hook: Hook): { validator: string; delegate: `0x${string}` } {
  const validator = extractValidatorFromHookId(hook.id)
  const [delegate] = decodeHookConfig<[`0x${string}`]>(hook, Mode.Signer, ['address'])

  return { validator, delegate }
}

function decodeMinAmountOutHook(hook: Hook): {
  validator: string
  slippage: bigint
  expiration: number
  signer: string
} {
  const validator = extractValidatorFromHookId(hook.id)
  const [slippage, expiration, signer] = decodeHookConfig<[bigint, bigint, string]>(hook, Mode.MinAmountsOut, [
    'uint256',
    'uint256',
    'address',
  ])

  return {
    validator,
    slippage: BigInt(slippage),
    expiration: Number(expiration),
    signer,
  }
}

export function buildToHook(validator: string, toAddress: string): Hook {
  const predicateId = computePredicateId(validator)
  const encodedModeConfig = defaultAbiCoder.encode(['address'], [toAddress])
  const encodedHookConfig = defaultAbiCoder.encode(['uint8', 'bytes'], [Mode.To, encodedModeConfig])
  return { id: predicateId, config: encodedHookConfig }
}

function decodeToHook(hook: Hook): { validator: string; toAddress: string } {
  const validator = extractValidatorFromHookId(hook.id)
  const [toAddress] = decodeHookConfig<[string]>(hook, Mode.To, ['address'])
  return { validator, toAddress }
}

export function buildTokensInHook(validator: string, tokens: string[], type: ListType): Hook {
  const predicateId = computePredicateId(validator)
  const encodedModeConfig = defaultAbiCoder.encode(['address[]', 'uint8'], [tokens, type])
  const encodedHookConfig = defaultAbiCoder.encode(['uint8', 'bytes'], [Mode.TokensIn, encodedModeConfig])
  return { id: predicateId, config: encodedHookConfig }
}

function decodeTokensInHook(hook: Hook): { validator: string; tokens: `0x${string}`[]; type: ListType } {
  const validator = extractValidatorFromHookId(hook.id)
  const [tokens, typeValue] = decodeHookConfig<[`0x${string}`[], number]>(hook, Mode.TokensIn, ['address[]', 'uint8'])
  const type = typeValue === ListType.Allow ? ListType.Allow : ListType.Deny
  return { validator, tokens, type }
}

export function buildDestinationChainHook(validator: string, chainIds: number[], type: ListType): Hook {
  const predicateId = computePredicateId(validator)
  const encodedModeConfig = defaultAbiCoder.encode(['uint256[]', 'uint8'], [chainIds, type])
  const encodedHookConfig = defaultAbiCoder.encode(['uint8', 'bytes'], [Mode.DestinationChain, encodedModeConfig])
  return { id: predicateId, config: encodedHookConfig }
}

function decodeDestinationChainHook(hook: Hook): {
  validator: string
  chains: number[]
  type: ListType
} {
  const validator = extractValidatorFromHookId(hook.id)
  const [chains, typeValue] = decodeHookConfig<[bigint[], number]>(hook, Mode.DestinationChain, ['uint256[]', 'uint8'])
  const type = typeValue === ListType.Allow ? ListType.Allow : ListType.Deny
  return { validator, chains: chains.map(Number), type }
}

export function decodeHook(hook: Hook) {
  const [mode] = defaultAbiCoder.decode(['uint8'], hook.config)

  switch (mode) {
    case Mode.Signer:
      return {
        type: 'DelegationHook',
        data: decodeDelegationHook(hook),
      } as DelegationHook
    case Mode.MinAmountsOut:
      return {
        type: 'MinAmountOutHook',
        data: decodeMinAmountOutHook(hook),
      } as MinAmountOutHook
    case Mode.To:
      return {
        type: 'ToHook',
        data: decodeToHook(hook),
      } as ToHook
    case Mode.TokensIn:
      return {
        type: 'TokensInHook',
        data: decodeTokensInHook(hook),
      } as TokensInHook
    case Mode.DestinationChain:
      return {
        type: 'DestinationChainHook',
        data: decodeDestinationChainHook(hook),
      } as DestinationChainHook
    default:
      throw new Error(`Unknown hook mode: ${mode}`)
  }
}

export type DelegationHook = {
  type: 'DelegationHook'
  data: ReturnType<typeof decodeDelegationHook>
}

export type MinAmountOutHook = {
  type: 'MinAmountOutHook'
  data: ReturnType<typeof decodeMinAmountOutHook>
}

export type ToHook = {
  type: 'ToHook'
  data: ReturnType<typeof decodeToHook>
}

export type TokensInHook = {
  type: 'TokensInHook'
  data: ReturnType<typeof decodeTokensInHook>
}

export type DestinationChainHook = {
  type: 'DestinationChainHook'
  data: ReturnType<typeof decodeDestinationChainHook>
}
