import { useFormContext } from 'react-hook-form'

import { ChainId } from '../../constants/chains'
import { TOKENS } from '../../constants/tokens'
import { useWallet } from '../../hooks/useWallet'
import { Token } from '../../types/constants'
import { ListType } from '../../types/hooks'
import Checkbox from '../Common/Checkbox'
import { MultiSelect } from '../Common/MultiSelect'

const getOptionValue = (option: Token) => option.address
const getOptionLabel = (option: Token) => option.symbol

function TokensSettings() {
  const {
    control,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext()

  const { chainId } = useWallet()

  const handleCheckChange = (checked: boolean, selectedType: ListType) => {
    if (checked) {
      setValue('tokensIn.type', selectedType)
    } else {
      setValue('tokensIn.type', undefined)
    }
  }

  return (
    <div className="flex justify-between items-center mt-4 gap-4">
      <MultiSelect
        label="Tokens In"
        name="tokensIn.tokens"
        control={control}
        options={TOKENS[chainId as ChainId] || []}
        placeholder="Select tokens"
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
      />
      <div className="flex gap-2 mt-[25px]">
        <Checkbox
          id="allowTokens"
          checked={watch('tokensIn.type') === ListType.Allow}
          onChange={(checked) => handleCheckChange(checked, ListType.Allow)}
          label="Allow"
          isError={!!errors.tokensIn?.type}
        />
        <Checkbox
          id="exceptTokens"
          checked={watch('tokensIn.type') === ListType.Deny}
          onChange={(checked) => handleCheckChange(checked, ListType.Deny)}
          label="Except"
          isError={!!errors.tokensIn?.type}
        />
      </div>
    </div>
  )
}

export default TokensSettings
