import { useQuery } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

const fetchChatHistory = async () => {
  const response = await API.getAccountChatMessages()
  return response.data
}

export const useChatHistory = () => {
  const { address } = useWallet()
  return useQuery({
    queryKey: ['chatHistory', address],
    queryFn: () => fetchChatHistory(),
    enabled: !!address,
  })
}
