import { SettlersGetRequest } from '@mimic-fi/v4-backend-helpers'
import { useQuery } from '@tanstack/react-query'

import API from '../api'

import { useAuth } from './useAuth'

const fetchSettlers = async (params: SettlersGetRequest) => {
  const response = await API.getSettlers({ ...params, enabled: true })
  return response.data
}

function useSettlers(params?: SettlersGetRequest) {
  const { isAuthenticated } = useAuth()
  return useQuery({
    queryKey: ['settlers', JSON.stringify(params)],
    queryFn: () => fetchSettlers(params || {}),
    enabled: isAuthenticated,
  })
}

export default useSettlers
