import { useState } from 'react'

import API from '../../api'
import Button from '../Common/Button'
import NetworkDropdown from '../General/NetworkDropdown'

import ErrorResponse from './ErrorResponse'
import SuccessResponse from './SuccessResponse'

type Network = {
  id: number
  name: string
}

const Rebalancing = () => {
  const [network, setNetwork] = useState<Network>({ id: 42161, name: 'ARBITRUM' })
  const [token1, setToken1] = useState('')
  const [percentage1, setPercentage1] = useState<number | ''>('')
  const [token2, setToken2] = useState('')
  const [threshold, setThreshold] = useState<number | ''>('')
  const [error, setError] = useState<string | null>(null)
  const [statusMessage, setStatusMessage] = useState<React.ReactNode>(null)

  const percentage2 = percentage1 !== '' ? 100 - Number(percentage1) : ''

  const handleStart = async () => {
    if (!network || !token1 || percentage1 === '' || !token2 || threshold === '') {
      setError('Please complete all fields before starting.')
      return
    }

    if (Number(percentage1) + Number(percentage2) !== 100) {
      setError('The sum of the percentages must be 100.')
      return
    }

    setError(null)
    const description = `Rebalance my portfolio to ${percentage1}% ${token1} / ${percentage2}% ${token2} on ${network.name} with a tolerance of ${threshold}%`

    try {
      await API.postAccountTask({
        description: description,
        frequency: 'minutely',
        interval: 10,
      })
      setStatusMessage(<SuccessResponse />)
    } catch (error) {
      console.error('Error creating task:', error)
      setStatusMessage(<ErrorResponse />)
    }
  }

  const handlePercentageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value)
    if (value >= 0 && value <= 100) {
      setPercentage1(value)
    }
  }

  return (
    <div className="w-full h-full pt-20 md:pt-0 p-4">
      <div className="space-y-4 p-4 flex items-center justify-between">
        <h2 className="text-xl font-semibold text-whiteFrost">Rebalancing</h2>
        {statusMessage && (
          <button onClick={() => setStatusMessage(null)} className="text-whiteFrost font-visbyBold px-2">
            x
          </button>
        )}
      </div>
      <div className="flex-grow flex items-center justify-center">
        {statusMessage ? (
          <>{statusMessage}</>
        ) : (
          <form className="w-full p-6 bg-backgroundSurface rounded-xl space-y-6">
            <NetworkDropdown defaultNetwork={network} onSelect={(selectedNetwork) => setNetwork(selectedNetwork)} />
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="token1" className="text-whiteFrost text-sm font-medium mb-1">
                  Token 1
                </label>
                <input
                  type="text"
                  id="token1"
                  value={token1}
                  onChange={(e) => setToken1(e.target.value)}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="percentage1" className="text-whiteFrost text-sm font-medium mb-1">
                  Percentage 1
                </label>
                <input
                  type="number"
                  id="percentage1"
                  value={percentage1}
                  onChange={handlePercentageChange}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                  min={0}
                  max={100}
                />
              </div>
            </div>
            <div className="flex gap-4">
              <div className="flex-1">
                <label htmlFor="token2" className="text-whiteFrost text-sm font-medium mb-1">
                  Token 2
                </label>
                <input
                  type="text"
                  id="token2"
                  value={token2}
                  onChange={(e) => setToken2(e.target.value)}
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
              <div className="flex-1">
                <label htmlFor="percentage2" className="text-whiteFrost text-sm font-medium mb-1">
                  Percentage 2
                </label>
                <input
                  type="number"
                  id="percentage2"
                  value={percentage2}
                  readOnly
                  className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm w-full"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label htmlFor="threshold" className="text-whiteFrost text-sm font-medium mb-1">
                Rebalancing Threshold
              </label>
              <input
                type="number"
                id="threshold"
                value={threshold}
                onChange={(e) => setThreshold(Number(e.target.value))}
                className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm"
                min={0}
                max={10}
              />
            </div>

            {error && <p className="text-red-500 text-sm text-center">{error}</p>}

            <Button type="button" onClick={handleStart} className="w-full">
              Start
            </Button>
          </form>
        )}
      </div>
    </div>
  )
}

export default Rebalancing
