import React from 'react'

import useIntents from '../../hooks/useIntents'
import Chain from '../Common/Chain'
import Container from '../Common/Container'
import Token from '../Common/Token'

import TaskStatusIcon from './TaskStatusIcon'

interface IntentsProps {
  taskId: string
}

const Intents: React.FC<IntentsProps> = ({ taskId }) => {
  const { data, isLoading, error } = useIntents(taskId)

  function shortAddress(address: string) {
    if (address.length <= 10) {
      return address
    }
    return address.substring(0, 5) + '...' + address.substring(address.length - 5)
  }
  console.log(data)

  return (
    <Container className="p-4" isLoading={isLoading} error={error?.message}>
      {data?.length === 0 ? (
        <div className="p-4 text-lg font-semibold text-gray-400 text-center">This task doesn’t have intents.</div>
      ) : (
        <>
          <h3 className="text-lg font-semibold text-whiteFrost mb-2">Intents</h3>
          <table className="w-full text-xs text-left text-whiteFrost min-w-full">
            <thead>
              <tr className="border-b border-gray-700">
                <th className="p-2">From</th>
                <th className="p-2">To</th>
                <th className="p-2">Token In</th>
                <th className="p-2">Token Out</th>
                <th className="p-2">Status</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((intent) => (
                <tr key={intent.id} className="border-b border-gray-700">
                  <td className="p-2">{shortAddress(intent.from)}</td>
                  <td className="p-2">{shortAddress(intent.to)}</td>
                  <td className="p-2">
                    <div className="flex items-center justify-start gap-[7px]">
                      <Chain chainId={intent.sourceChain} />
                      <Token chainId={intent.sourceChain} address={intent.tokensIn[0].token} />
                      {(intent.tokensIn[0].amountUsd * intent.tokensIn[0].price).toFixed(2)}
                    </div>
                  </td>
                  <td className="p-2">
                    <div className="flex items-center justify-start gap-[7px]">
                      <Chain chainId={intent.destinationChain} />
                      <Token chainId={intent.destinationChain} address={intent.tokensOut[0].token} />
                      {(intent.tokensOut[0].minAmountUsd * intent.tokensOut[0].price).toFixed(2)}
                    </div>
                  </td>
                  <td className="p-2">
                    <TaskStatusIcon status={intent.status} />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </>
      )}
    </Container>
  )
}

export default Intents
