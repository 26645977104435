import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import AssetsLogo from './AssetsLogo'
import SafeguardLogo from './SafeguardLogo'
import TasksLogo from './TasksLogo'

const Sidebar = () => {
  const location = useLocation()
  return (
    <div
      className="bg-headerBg h-20 w-full flex flex-row justify-center items-center relative m-0
      md:fixed md:top-0 md:left-0 md:py-2 md:px-6 md:mt-[60px]
      md:h-screen md:w-20 md:flex-col md:justify-between md:items-center"
    >
      <div className="flex flex-row items-center space-x-6 md:flex-col md:space-y-6 md:space-x-0">
        <NavItem
          to="/assets"
          logo={<AssetsLogo fill={location.pathname.includes('/assets') ? '#5CE6D5' : '#96969C'} />}
        />
        <NavItem
          to="/safeguards"
          logo={<SafeguardLogo fill={location.pathname.includes('/safeguards') ? '#5CE6D5' : '#96969C'} />}
        />
        <NavItem to="/tasks" logo={<TasksLogo fill={location.pathname.includes('/tasks') ? '#5CE6D5' : '#96969C'} />} />
      </div>
    </div>
  )
}

interface NavItemProps {
  to: string
  logo: React.ReactElement
}

const NavItem: React.FC<NavItemProps> = ({ to, logo }) => (
  <NavLink
    to={to}
    className={({ isActive }: { isActive: boolean }) =>
      `p-4 rounded-[16px] transition-colors ${isActive ? 'bg-[#27252b]' : 'hover:bg-gray-700'}`
    }
  >
    {logo}
  </NavLink>
)

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  logo: PropTypes.element.isRequired,
}

export default Sidebar
