import { useQuery } from '@tanstack/react-query'
import axios from 'axios'

const fetchToken = async (chainId: number, address: string) => {
  const response = await axios.get(`https://api.mimic.fi/public/tokens/${chainId}/${address}`)
  return response.data
}

export type TokenResponse = Awaited<ReturnType<typeof fetchToken>>

export default function useToken(chainId: number, address: string) {
  return useQuery<TokenResponse>({
    queryKey: ['token', chainId, address],
    queryFn: () => fetchToken(chainId, address),
  })
}
