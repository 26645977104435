import { useMutation, useQueryClient } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

export function useResetChat() {
  const queryClient = useQueryClient()
  const { address } = useWallet()

  return useMutation({
    mutationFn: async () => {
      const response = await API.deleteAccountChat()
      if (response.status !== 200) {
        console.error('Failed to reset conversation')
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['chatHistory', address] })
    },
  })
}
