import { useQuery } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

async function fetchChainSettings(chainId: number) {
  const response = await API.getChainSettings(chainId)
  return response.data
}

function useChainSettings() {
  const { address, chainId } = useWallet()
  return useQuery({
    queryKey: ['chainSettings', chainId],
    queryFn: () => fetchChainSettings(chainId!),
    enabled: !!address,
  })
}

export default useChainSettings
