import { useEffect, useRef, useState } from 'react'
import { Control, Controller, FieldValues, Path, PathValue } from 'react-hook-form'
import { X } from 'lucide-react'

interface Props<T extends FieldValues, OptionType> {
  label?: string
  name: Path<T>
  control: Control<T>
  options: OptionType[]
  placeholder?: string
  getOptionValue: (option: OptionType) => React.Key
  getOptionLabel: (option: OptionType) => string
}

export function MultiSelect<T extends FieldValues, OptionType>({
  label,
  name,
  control,
  options,
  placeholder,
  getOptionValue,
  getOptionLabel,
}: Props<T, OptionType>) {
  const dropdownRef = useRef<HTMLUListElement>(null)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[] as PathValue<T, Path<T>>}
      render={({ field, fieldState: { error } }) => {
        const { value, onChange } = field
        const [inputValue, setInputValue] = useState('')
        const [filteredOptions, setFilteredOptions] = useState<OptionType[]>([])
        const [showOptions, setShowOptions] = useState(false)

        useEffect(() => {
          if (inputValue) {
            const selectedValues = (value || []).map((val: React.Key) => val)
            const filtered = options.filter(
              (option) =>
                getOptionLabel(option).toLowerCase().includes(inputValue.toLowerCase()) &&
                !selectedValues.includes(getOptionValue(option))
            )
            setFilteredOptions(filtered)
            setShowOptions(filtered.length > 0)
          } else {
            setShowOptions(false)
          }
        }, [inputValue, options, value])

        const handleSelectOption = (option: OptionType) => {
          const optionValue = getOptionValue(option)
          onChange([...(value || []), optionValue])
          setInputValue('')
          setShowOptions(false)
        }

        const handleRemove = (optionValue: React.Key) => {
          onChange((value || []).filter((val: React.Key) => val !== optionValue))
        }

        const handleClickOutside = (event: MouseEvent) => {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setShowOptions(false)
          }
        }

        useEffect(() => {
          document.addEventListener('mousedown', handleClickOutside)
          return () => {
            document.removeEventListener('mousedown', handleClickOutside)
          }
        }, [])

        const selectedOptions = (value || []).map((val: React.Key) =>
          options.find((option) => getOptionValue(option) === val)
        )

        return (
          <div className="space-y-2 relative w-full">
            {label && (
              <label className="block text-whiteFrost text-sm font-medium mb-1" htmlFor={name}>
                {label}
              </label>
            )}
            <div
              className="flex flex-wrap items-center gap-2 px-3 py-1 text-lg border rounded-md h-auto border-none outline-none text-whiteFrost placeholder:text-whiteFrost bg-backgroundAvobeSurface"
              onClick={() => {
                const input = document.getElementById(`input-${name}`) as HTMLInputElement
                input?.focus()
              }}
            >
              {selectedOptions.map(
                (item) =>
                  item && (
                    <span
                      key={getOptionValue(item)}
                      className="flex items-center gap-1 px-2 py-1 text-sm bg-primary text-oceanGround text-xs rounded-md"
                    >
                      {getOptionLabel(item)}
                      <button
                        type="button"
                        onClick={() => handleRemove(getOptionValue(item))}
                        className="text-oceanGround text-xs"
                      >
                        <X size={14} />
                      </button>
                    </span>
                  )
              )}
              <input
                id={`input-${name}`}
                type="text"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onFocus={() => {
                  if (inputValue && filteredOptions.length > 0) {
                    setShowOptions(true)
                  }
                }}
                placeholder={placeholder}
                className="flex-1 border-none outline-none focus:ring-0 text-whiteFrost placeholder-whiteFrost bg-[transparent] text-xs p-3"
              />
            </div>
            {error && <p className="text-red-500">{error.message}</p>}
            {showOptions && (
              <ul
                ref={dropdownRef}
                className="absolute z-10 w-full max-h-40 overflow-y-auto mt-2 bg-backgroundBelow shadow-lg rounded-lg p-2 space-y-2 z-10"
              >
                {filteredOptions.map((option) => (
                  <li
                    key={getOptionValue(option)}
                    className="w-full text-left px-2 py-1 rounded-md hover:bg-primaryHover text-whiteFrost text-xs"
                    onClick={() => handleSelectOption(option)}
                  >
                    {getOptionLabel(option)}
                  </li>
                ))}
              </ul>
            )}
          </div>
        )
      }}
    />
  )
}
