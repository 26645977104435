import { useWallet } from '../../hooks/useWallet'
import Chat from '../Chat'
import AwaitingConnection from '../EmptyStates/AwaitingConnection'
import NotConnected from '../EmptyStates/NotConnected'
import AutoExit from '../Templates/AutoExit'
import BlockTrade from '../Templates/BlockTrade'
import Rebalancing from '../Templates/Rebalancing'

import Settings from './Settings'

function Safeguard() {
  const { isConnected, isConnecting } = useWallet()
  const option = localStorage.getItem('option')
  const renderComponent = () => {
    switch (option) {
      case 'chat':
        return <Chat />
      case 'rebalancing':
        return <Rebalancing />
      case 'autoExit':
        return <AutoExit />
      case 'blockTrade':
        return <BlockTrade />
      default:
        return <Chat />
    }
  }

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-4 pt-20 md:pt-0 md:pl-20 p-4">
      {!isConnected ? (
        isConnecting ? (
          <AwaitingConnection />
        ) : (
          <NotConnected />
        )
      ) : (
        <>
          <div className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto  p-0 md:p-4 min-h-[calc(100vh-220px)]">
            <Settings />
          </div>
          <div className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto  p-0 md:p-4 min-h-[calc(100vh-220px)]">
            {renderComponent()}
          </div>
        </>
      )}
    </div>
  )
}

export default Safeguard
