import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'

import { AccountSettings } from '../../hooks/useSettingsForm'
import { Dropdown } from '../Common/Dropdown'
import Input from '../Common/Input'

interface Props {
  field: keyof AccountSettings
  title: string
  defaultAddress: string
  defaultLabel: string
  label?: string
}

function AddressSection({ field, defaultAddress, defaultLabel, title, label }: Props) {
  const {
    register,
    formState: { errors },
    setValue,
    watch,
  } = useFormContext()

  const fieldValue = watch(field)
  const fieldError = errors[field]?.message
  const recipientType = fieldValue === defaultAddress ? defaultAddress : fieldValue === undefined ? '' : 'other'

  const handleRecipientTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value

    if (selectedValue === '') {
      setValue(field, undefined)
    } else if (selectedValue === defaultAddress) {
      setValue(field, defaultAddress)
    } else if (selectedValue === 'other') {
      setValue(field, '')
    }
  }

  const dropdownOptions = [
    { value: '', label: 'None' },
    { value: defaultAddress, label: defaultLabel },
    { value: 'other', label: 'Other' },
  ]

  return (
    <div className="flex gap-4 mt-4">
      <div className="flex-1 flex flex-col">
        <label htmlFor="token" className="text-whiteFrost text-sm font-medium mb-1">
          {title}
        </label>
        <Dropdown
          name="recipientType"
          label={title}
          value={recipientType}
          onChange={handleRecipientTypeChange}
          options={dropdownOptions}
          placeholder="Select an option"
        />
      </div>
      {recipientType === 'other' && (
        <div className="flex-1">
          <Input
            label={label}
            type="text"
            placeholder="0x..."
            {...register(field)}
            error={fieldError as string}
            className={classNames('w-full', fieldError && 'border-red-500')}
          />
        </div>
      )}
    </div>
  )
}

export default AddressSection
