import { ReactComponent as Wallet } from '../../assets/wallet.svg'

function NotConnected() {
  return (
    <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
      <Wallet className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Wallet not connected</h2>
      <p className="mt-2 text-lg">Please connect your wallet to start chatting.</p>
    </div>
  )
}

export default NotConnected
