import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { ReactComponent as Logo } from '../assets/logo.svg'

import Button from './Common/Button'

const Welcome: React.FC = () => {
  const navigate = useNavigate()
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleButtonClick = (value: string) => {
    localStorage.setItem('option', value)
    navigate('/assets')
  }

  return (
    <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
      <Logo className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Welcome to Mimic Protocol playground</h2>
      <p className="mt-2 text-lg">How would you like to start?</p>
      <div className="flex mt-6 gap-10">
        <Button onClick={() => handleButtonClick('chat')}>Let&apos;s Chat</Button>
        <div className="relative">
          <Button onClick={() => setDropdownOpen(!isDropdownOpen)}>Go to Template</Button>
          {isDropdownOpen && (
            <div className="absolute left-0 mt-2 w-48 bg-backgroundAvobeSurface shadow-lg rounded-lg p-2 space-y-2 z-10">
              <button
                onClick={() => handleButtonClick('rebalancing')}
                className="w-full text-left px-4 py-2 rounded-md hover:bg-primaryHover text-whiteFrost"
              >
                Rebalancing
              </button>
              <button
                onClick={() => handleButtonClick('autoExit')}
                className="w-full text-left px-4 py-2 rounded-md hover:bg-primaryHover text-whiteFrost"
              >
                Auto Exit
              </button>
              <button
                onClick={() => handleButtonClick('blockTrade')}
                className="w-full text-left px-4 py-2 rounded-md hover:bg-primaryHover text-whiteFrost"
              >
                Block Trade
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default Welcome
