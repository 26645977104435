import { useQuery } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

const fetchTasks = async () => {
  const response = await API.getAccountTasks()
  const tasks = response.data
  return tasks
}

function useTasks() {
  const { address } = useWallet()

  return useQuery({
    queryKey: ['tasks', address],
    queryFn: () => fetchTasks(),
    enabled: !!address,
  })
}

export default useTasks
