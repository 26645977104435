import React from 'react'

interface Props {
  id: string
  checked: boolean
  onChange: (checked: boolean) => void
  label: string
  isLoading?: boolean
  isError?: boolean
}

function Checkbox({ id, checked, onChange, label, isLoading, isError }: Props) {
  return (
    <div className="flex flex-col space-y-1">
      <div className="flex items-center space-x-2 text-whiteFrost rounded-lg p-3 pr-10">
        <input
          type="checkbox"
          id={id}
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
          className={`h-5 w-5 appearance-none bg-backgroundAvobeSurface text-primary border-2 border-backgroundAvobeSurface rounded-lg checked:bg-primary checked:border-primary focus:outline-none ${
            isError ? 'ring-2 ring-red-500' : ''
          }`}
          disabled={isLoading}
        />
        <label htmlFor={id} className="text-sm text-whiteFrost">
          {label}
        </label>
      </div>
    </div>
  )
}

export default Checkbox
