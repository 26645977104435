import { ReactComponent as Error } from '../../assets/error.svg'

function ErrorResponse() {
  return (
    <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
      <Error className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">There was an error creating your task.</h2>
      <p className="mt-2 text-lg">Please try again later.</p>
    </div>
  )
}

export default ErrorResponse
