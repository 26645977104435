import { useEffect } from 'react'
import { BigNumber, ethers } from 'ethers'
import { erc20Abi } from 'viem'
import { useWaitForTransactionReceipt, useWriteContract } from 'wagmi'

import useSettlers from '../hooks/useSettlers'

interface UseAllowanceProps {
  chainId: number | undefined
  refetchTokens: () => void
}

function formatAllowance(allowance: string, decimals?: number) {
  const maxUint256 = ethers.constants.MaxUint256
  const MaxThreshold = maxUint256.mul(80).div(100)

  const allowanceBigNumber = ethers.BigNumber.from(allowance)

  if (allowanceBigNumber.gte(MaxThreshold)) {
    return 'Infinite'
  }

  const tokenDecimals = decimals ?? 18

  const allowanceAmount = parseFloat(ethers.utils.formatUnits(allowanceBigNumber, tokenDecimals))

  if (allowanceAmount > 1_000_000_000) {
    return allowanceAmount.toExponential(2)
  } else {
    return allowanceAmount.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 6,
    })
  }
}

const isApproved = (allowance?: string) => BigNumber.from(allowance).gt(0)

function useAllowance({ chainId, refetchTokens }: UseAllowanceProps) {
  const { data: settlers } = useSettlers({ chainId })

  const { writeContract, data: hash, isPending } = useWriteContract()
  const { isLoading: isWaitingForTransaction, data: txData } = useWaitForTransactionReceipt({
    hash,
  })

  useEffect(() => {
    if (txData) {
      refetchTokens()
    }
  }, [txData, refetchTokens])

  const handleAllowance = async (tokenAddress: string, revoke = false) => {
    if (!settlers || settlers.length === 0) {
      console.error('No settlers available')
      return
    }

    const allowanceAmount = revoke ? ethers.constants.Zero : ethers.constants.MaxUint256

    for (const settler of settlers) {
      if (settler.enabled) {
        try {
          await writeContract({
            address: tokenAddress as `0x${string}`,
            abi: erc20Abi,
            functionName: 'approve',
            args: [settler.address as `0x${string}`, allowanceAmount.toBigInt()],
          })
        } catch (error) {
          console.error(`Error ${revoke ? 'revoking' : 'approving'} allowance for settler ${settler.address}:`, error)
        }
      }
    }
  }

  return {
    handleAllowance,
    isApproved,
    formatAllowance,
    isLoading: isPending || isWaitingForTransaction,
  }
}

export default useAllowance
