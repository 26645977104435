import { CHAINS } from '../../constants/chains'
import { isSupportedChain } from '../../utils'

import Tooltip from './Tooltip'

interface Props {
  chainId: number
  size?: number
}

function Chain({ chainId, size = 24 }: Props) {
  if (!isSupportedChain(chainId)) return chainId

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  }

  const chain = CHAINS[chainId as keyof typeof CHAINS]
  return (
    <Tooltip content={chain.name}>
      <div className="flex items-center justify-center rounded overflow-hidden" style={sizeStyle}>
        <img src={chain.logoUrl} alt={chain.name} className="object-contain" style={sizeStyle} />
      </div>
    </Tooltip>
  )
}

export default Chain
