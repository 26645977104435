import React, { forwardRef } from 'react'
import classNames from 'classnames'
import { X } from 'lucide-react'

type Props = {
  label?: string
  error?: string
  className?: string
  inputClassName?: string
  selectedItems?: string[]
  onRemoveItem?: (item: string) => void
  id?: string
} & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>

const Input = forwardRef<HTMLInputElement, Props>(
  ({ label, error, className, selectedItems = [], onRemoveItem, inputClassName, ...props }, ref) => {
    return (
      <>
        {label && (
          <label className="block text-sm font-medium text-whiteFrost mb-1" htmlFor={props.id}>
            {label}
          </label>
        )}
        <div className={classNames('flex flex-wrap items-center gap-2 text-lg rounded-md', className)}>
          {selectedItems.map((item) => (
            <span
              key={item}
              className="flex items-center gap-1 px-2 py-1 text-sm bg-primary text-primary-foreground rounded-md"
            >
              {item}
              <button type="button" onClick={() => onRemoveItem?.(item)} className="text-primary-foreground">
                <X size={14} />
              </button>
            </span>
          ))}
          <input
            ref={ref}
            className={classNames(
              'flex-1 border-none outline-none text-whiteFrost placeholder:text-whiteFrost bg-backgroundAvobeSurface rounded-lg p-3 text-sm',
              inputClassName
            )}
            {...props}
          />
        </div>
        {error && <p className="text-red-500">{error}</p>}
      </>
    )
  }
)

Input.displayName = 'Input'
export default Input
