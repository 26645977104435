'use client'
import { useEffect, useState } from 'react'
import { switchChain } from '@wagmi/core'
import { optimism } from 'viem/chains'
import { useAccount } from 'wagmi'

import { AVAILABLE_CHAIN_IDS, wagmiConfig } from '../wagmi'

import { useWallet } from './useWallet'

const useCheckNetwork = () => {
  const { address } = useAccount()
  const { chainId } = useWallet()
  const [isCorrectNetwork, setIsCorrectNetwork] = useState(true)
  const [isSwitching, setIsSwitching] = useState(false)

  useEffect(() => {
    setIsCorrectNetwork(!address || (!!chainId && AVAILABLE_CHAIN_IDS.includes(chainId)))
  }, [chainId])

  const switchToCorrectNetwork = async (targetChainId: number = optimism.id) => {
    try {
      setIsSwitching(true)
      await switchChain(wagmiConfig, { chainId: targetChainId as never })
    } catch (error) {
      console.error('Error switching network:', error)
    } finally {
      setIsSwitching(false)
    }
  }

  return { isCorrectNetwork, switchToCorrectNetwork, isSwitching }
}

export default useCheckNetwork
