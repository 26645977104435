import classNames from 'classnames'
import { LoaderCircle } from 'lucide-react'

interface Props {
  size?: 'sm' | 'md' | 'lg'
  className?: string
}

const sizeClasses = {
  sm: 'w-4 h-4',
  md: 'w-8 h-8',
  lg: 'w-12 h-12',
}

function Spinner({ size = 'md', className }: Props) {
  return <LoaderCircle className={classNames('animate-spin text-primary', sizeClasses[size], className)} />
}

export default Spinner
