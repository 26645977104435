import { ReactComponent as Wallet } from '../../assets/wallet.svg'

function AwaitingConnection() {
  return (
    <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
      <Wallet className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Awaiting connection...</h2>
      <p className="mt-2 text-lg">Hang tight! We&apos;re linking to your wallet.</p>
    </div>
  )
}

export default AwaitingConnection
