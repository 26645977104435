import { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import useAccount from '../../hooks/useAccount'
import { useSettings } from '../../hooks/useSettings'
import useSettingsForm, { AccountSettings } from '../../hooks/useSettingsForm'
import { decodeHook } from '../../hooks/utils'
import { Hook } from '../../types/hooks'
import Button from '../Common/Button'
import Container from '../Common/Container'

import ChainsSettings from './ChainsSettings'
import DelegationSettings from './DelegationSettings'
import MinAmountOutSettings from './MinAmountOutSettings'
import RecipientSettings from './RecipientSettings'
import TokensSettings from './TokensSettings'

const EMPTY_ACCOUNT_SETTINGS: AccountSettings = {
  recipient: undefined,
  signer: undefined,
  slippage: undefined,
  expiration: undefined,
  tokensIn: undefined,
  destinationChains: undefined,
}

function setupSettings(hooks: Hook[]): AccountSettings {
  const decodedHooks = hooks.map(decodeHook)

  const settings: AccountSettings = EMPTY_ACCOUNT_SETTINGS

  for (const hook of decodedHooks) {
    switch (hook.type) {
      case 'ToHook':
        const toAddress = hook.data.toAddress
        settings.recipient = toAddress as `0x${string}`

        break
      case 'DelegationHook':
        const delegate = hook.data.delegate
        settings.signer = delegate
        break
      case 'MinAmountOutHook':
        settings.slippage = parseFloat(hook.data.slippage.toString()) / 1e16
        settings.expiration = hook.data.expiration / 60
        break
      case 'TokensInHook':
        settings.tokensIn = {
          type: hook.data.type,
          tokens: hook.data.tokens.map((address) => address.toLowerCase() as `0x${string}`),
        }
        break
      case 'DestinationChainHook':
        settings.destinationChains = {
          type: hook.data.type,
          chains: hook.data.chains,
        }
        break
    }
  }

  return settings
}

function Settings() {
  const { data: account, isLoading: isLoadingAccount, error: errorAccount } = useAccount()
  const { isLoading, error, updateSettingsMutation } = useSettings()

  const methods = useSettingsForm()
  const { handleSubmit, reset } = methods

  useEffect(() => {
    if (!isLoadingAccount) {
      if (!account || !account.message) {
        reset(EMPTY_ACCOUNT_SETTINGS)
      } else {
        reset(setupSettings(account.message.hooks.init))
      }
    }
  }, [isLoadingAccount, account, reset])

  const onSubmit = (data: AccountSettings) => {
    updateSettingsMutation.mutate(data)
  }

  return (
    <Container isLoading={isLoading || isLoadingAccount} error={error?.message || errorAccount?.message} wide>
      <FormProvider {...methods}>
        <div className="w-full h-full pt-20 md:pt-0 p-4">
          <div className="space-y-4 p-4">
            <h2 className="text-xl font-semibold">Safeguards</h2>
          </div>
          <div className="flex-grow flex items-center justify-center">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full p-1 md:p-6 bg-backgroundSurface rounded-xl space-y-6"
            >
              <RecipientSettings />
              <DelegationSettings />
              <MinAmountOutSettings />
              <TokensSettings />
              <ChainsSettings />
              <Button type="submit" disabled={isLoading || updateSettingsMutation.isPending} className="w-full">
                {updateSettingsMutation.isPending ? 'Saving...' : 'Save'}
              </Button>
            </form>
          </div>
        </div>
      </FormProvider>
    </Container>
  )
}

export default Settings
