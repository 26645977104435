import { useEffect } from 'react'
import { AccountUpdateRequest } from '@mimic-fi/v4-backend-helpers'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import API from '../api'

import useChainSettings from './useChainSettings'
import { AccountSettings } from './useSettingsForm'
import useSettlers from './useSettlers'
import { useWallet } from './useWallet'
import {
  buildDelegationHook,
  buildDestinationChainHook,
  buildHooksMessage,
  buildMinAmountOutHook,
  buildToHook,
  buildTokensInHook,
} from './utils'

export const MIMIC_SIGNER = '0xD24E64D65f0d33D7Ae80a75550Db47A563378ec4'

export const useSettings = () => {
  const { address, chainId, signTypedDataAsync } = useWallet()
  const queryClient = useQueryClient()
  const invalidateAccountQuery = () => queryClient.invalidateQueries({ queryKey: ['account', address] })
  const { data: chainSettings, isLoading: isLoadingChainSettings, error: errorChainSettings } = useChainSettings()
  const { data: settlers, isLoading: isLoadingSettler, error: errorSettler } = useSettlers({ chainId: chainId! })

  const settler = settlers?.[0]

  useEffect(() => {
    if (errorChainSettings) {
      console.error(errorChainSettings)
    }

    if (errorSettler) {
      console.error(errorSettler)
    }
  }, [errorChainSettings, errorSettler])

  const updateSettingsMutation = useMutation({
    mutationFn: async (settings: AccountSettings) => {
      if (Object.values(settings).every((v) => v === undefined)) {
        return await API.updateAccount()
      }
      const { recipient, slippage, expiration, signer, tokensIn, destinationChains } = settings

      const hooksArray = []

      if (recipient) {
        const recipientHook = buildToHook(chainSettings!.intentValidator, recipient)
        hooksArray.push(recipientHook)
      }

      if (signer) {
        const delegationHook = buildDelegationHook(chainSettings!.intentValidator, signer)
        hooksArray.push(delegationHook)
      }

      if (slippage !== undefined && expiration !== undefined) {
        const minAmountOutHook = buildMinAmountOutHook(
          chainSettings!.intentValidator,
          BigInt(Math.floor(slippage * 1e16)),
          expiration * 60,
          signer || MIMIC_SIGNER
        )
        hooksArray.push(minAmountOutHook)
      }

      if (tokensIn) {
        const tokensInHook = buildTokensInHook(chainSettings!.intentValidator, tokensIn.tokens, tokensIn.type!)
        hooksArray.push(tokensInHook)
      }

      if (destinationChains) {
        const destinationChainHook = buildDestinationChainHook(
          chainSettings!.intentValidator,
          destinationChains.chains,
          destinationChains.type!
        )
        hooksArray.push(destinationChainHook)
      }

      const { domain, types, hooks } = buildHooksMessage(settler!.address, hooksArray)

      const signature = await signTypedDataAsync({
        domain,
        types,
        primaryType: 'Hooks',
        message: hooks as never,
      })
      const params: AccountUpdateRequest = {
        signature,
        message: JSON.stringify({ domain, types, hooks }),
      }
      const response = await API.updateAccount(params)
      return response.data
    },
    onSuccess: () => invalidateAccountQuery(),
    onError: (error) => {
      console.error(error)
      invalidateAccountQuery()
    },
  })

  return {
    isLoading: isLoadingChainSettings || isLoadingSettler || updateSettingsMutation.isPending,
    error: errorChainSettings || errorSettler,
    updateSettingsMutation,
  }
}
