'use client'
import { useEffect, useMemo, useState } from 'react'

import useCheckNetwork from '../hooks/useCheckNetwork'
import useSettlers from '../hooks/useSettlers'
import { useWallet } from '../hooks/useWallet'
import { wagmiConfig } from '../wagmi'

import Button from './Common/Button'
import { Dropdown } from './Common/Dropdown'
import Modal from './Common/Modal'

function NetworkModal() {
  const { address, chainId, isConnected } = useWallet()
  const { isCorrectNetwork, switchToCorrectNetwork, isSwitching } = useCheckNetwork()
  const [isInternalOpen, setIsInternalOpen] = useState(false)
  const [selectedNetwork, setSelectedNetwork] = useState<number | null>(null)
  const { data: settlers, isLoading: isSettlersLoading } = useSettlers()
  const [isInitialized, setIsInitialized] = useState(false)

  const supportedChains = useMemo(() => (settlers ? settlers.map((settler) => settler.chainId) : []), [settlers])
  const isInSupportedChains = useMemo(
    () => (!isConnected ? true : supportedChains.includes(chainId || 0)),
    [isConnected, chainId, supportedChains]
  )
  const chainOptions = useMemo(
    () => supportedChains.map((id) => wagmiConfig.chains.find((chain) => chain.id === id)),
    [supportedChains]
  )

  useEffect(() => {
    if (!isSettlersLoading) {
      const shouldShowModal = isConnected && (!isInSupportedChains || !isCorrectNetwork || !address)
      setIsInternalOpen(shouldShowModal)
      if (shouldShowModal) {
        setSelectedNetwork(supportedChains[0] || null)
      }
      setIsInitialized(true)
    }
  }, [isSettlersLoading, isConnected, isInSupportedChains, isCorrectNetwork, address, chainId, supportedChains])

  const handleClose = () => {
    if (isCorrectNetwork && address) {
      setIsInternalOpen(false)
    }
  }

  const handleNetworkChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNetwork(Number(event.target.value))
  }

  const handleSwitchNetwork = async () => {
    if (selectedNetwork) {
      await switchToCorrectNetwork(selectedNetwork)
      if (isCorrectNetwork && address && chainId !== undefined) {
        handleClose()
      }
    }
  }

  if (!isInitialized) {
    return <></>
  }

  return (
    <Modal isOpen={isInternalOpen} title="Network Selection">
      <p className="text-black-700">
        The chain you are connected to is currently not supported. Please select one of the following:
      </p>

      <div className="mt-4 w-full">
        <Dropdown
          name="network"
          value={selectedNetwork || ''}
          onChange={handleNetworkChange}
          options={chainOptions.map((chain) => ({ value: chain?.id || 0, label: chain?.name || '' }))}
          className="w-full"
        />
      </div>

      <div className="mt-4">
        <Button onClick={handleSwitchNetwork} disabled={isSwitching || !selectedNetwork} className="w-full">
          {isSwitching ? 'Switching...' : 'Switch Network'}
        </Button>
      </div>
    </Modal>
  )
}

export default NetworkModal
