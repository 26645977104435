import { Link } from 'react-router-dom'
import { ConnectButton } from '@rainbow-me/rainbowkit'

import { ReactComponent as Logo } from '../assets/logolong.svg'

function Header() {
  return (
    <header className="w-full bg-headerBg px-[27px] py-2 sm:px-6 sm:py-3 md:px-[27px] md:py-4 flex items-center justify-between">
      <Link to="/">
        <Logo className="h-[22px] w-[150px]" />
      </Link>
      <div className="flex items-center space-x-4">
        <ConnectButton.Custom>
          {({ account, chain, openConnectModal, openAccountModal, openChainModal, mounted }) => {
            const connected = mounted && account && chain

            return (
              <div className="inline-block">
                {connected ? (
                  <div
                    className="flex flex-col md:flex-row items-end md:items-center space-y-[10px]
                     md:space-x-2 md:space-y-0"
                  >
                    <button
                      onClick={openChainModal}
                      className="inline-block px-[0.1rem] py-[0.1rem] border-2 border-whiteFrost rounded-lg bg-whiteFrost font-visby font-bold text-[14px] text-oceanGround cursor-pointer transition-colors duration-300 hover:bg-primaryHover hover:border-primaryHover
                      md:px-4 md:py-2"
                    >
                      {chain.name}
                    </button>
                    <button
                      onClick={openAccountModal}
                      className="inline-block px-[0.1rem] py-[0.1rem] border-2 border-whiteFrost rounded-lg bg-whiteFrost font-visby font-bold text-[14px] text-oceanGround cursor-pointer transition-colors duration-300 hover:bg-primaryHover hover:border-primaryHover
                      md:px-4 md:py-2"
                    >
                      {account.displayName} ({account.displayBalance})
                    </button>
                  </div>
                ) : (
                  <button
                    onClick={openConnectModal}
                    className="inline-block px-10 py-2.5 border-2 border-primary rounded-lg bg-primary font-visby font-bold text-[16px] leading-[150%] text-black cursor-pointer transition-colors duration-300 hover:bg-primaryHover hover:border-primaryHover"
                  >
                    Connect Wallet
                  </button>
                )}
              </div>
            )
          }}
        </ConnectButton.Custom>
      </div>
    </header>
  )
}

export default Header
