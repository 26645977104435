import { useState } from 'react'
import PropTypes from 'prop-types'

type Network = {
  id: number
  name: string
}

interface NetworkDropdownProps {
  defaultNetwork: Network
  onSelect: (network: Network) => void
}

const networks: Network[] = [
  { id: 1, name: 'MAINNET' },
  { id: 10, name: 'OPTIMISM' },
  { id: 137, name: 'POLYGON' },
  { id: 100, name: 'GNOSIS' },
  { id: 42161, name: 'ARBITRUM' },
  { id: 1101, name: 'ZKEVM' },
  { id: 56, name: 'BSC' },
  { id: 8453, name: 'BASE' },
  { id: 43114, name: 'AVALANCHE' },
  { id: 250, name: 'FANTOM' },
  { id: 1313161554, name: 'AURORA' },
]

const NetworkDropdown: React.FC<NetworkDropdownProps> = ({ defaultNetwork, onSelect }) => {
  const [selectedNetwork, setSelectedNetwork] = useState<Network>(defaultNetwork)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleSelect = (network: Network) => {
    setSelectedNetwork(network)
    setDropdownOpen(false)
    onSelect(network)
  }

  return (
    <div className="relative flex flex-col">
      <label htmlFor="network" className="text-whiteFrost text-sm font-medium mb-1">
        Network
      </label>
      <input
        type="text"
        id="network"
        value={selectedNetwork.name}
        readOnly
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm cursor-pointer"
      />
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 mt-2 bg-backgroundBelow shadow-lg rounded-lg p-2 space-y-2 z-10">
          {networks.map((network) => (
            <button
              key={network.id}
              onClick={() => handleSelect(network)}
              className="w-full text-left px-2 py-1 rounded-md hover:bg-primaryHover text-whiteFrost text-xs"
            >
              {network.name}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

NetworkDropdown.propTypes = {
  defaultNetwork: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default NetworkDropdown
