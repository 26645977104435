import classNames from 'classnames'

type Props = React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

function Button({ children, className, ...props }: Props) {
  return (
    <button
      className={classNames(
        'px-6 py-2 rounded-lg bg-primary hover:bg-primaryHover font-semibold text-black',
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}

export default Button
