import { useState } from 'react'
import PropTypes from 'prop-types'

export enum TaskFrequency {
  once = 'once',
  secondly = 'secondly',
  minutely = 'minutely',
  hourly = 'hourly',
  daily = 'daily',
  weekly = 'weekly',
  monthly = 'monthly',
  yearly = 'yearly',
}

interface TaskFrequencyDropdownProps {
  defaultFrequency?: TaskFrequency
  onSelect: (frequency: TaskFrequency) => void
}

const TaskFrequencyDropdown: React.FC<TaskFrequencyDropdownProps> = ({ defaultFrequency, onSelect }) => {
  const [selectedFrequency, setSelectedFrequency] = useState<TaskFrequency | null>(defaultFrequency || null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleSelect = (frequency: TaskFrequency) => {
    setSelectedFrequency(frequency)
    setDropdownOpen(false)
    onSelect(frequency)
  }

  return (
    <div className="relative flex flex-col">
      <label htmlFor="frequency" className="text-whiteFrost text-sm font-medium mb-1">
        Frequency
      </label>
      <input
        type="text"
        id="frequency"
        value={selectedFrequency ? selectedFrequency : ''}
        readOnly
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        placeholder="Select frequency"
        className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm cursor-pointer"
      />
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 mt-2 bg-backgroundBelow shadow-lg rounded-lg p-2 space-y-2 z-10">
          {Object.values(TaskFrequency).map((frequency) => (
            <button
              key={frequency}
              onClick={() => handleSelect(frequency as TaskFrequency)}
              className="w-full text-left px-2 py-1 rounded-md hover:bg-primaryHover text-whiteFrost text-xs"
            >
              {frequency.charAt(0).toUpperCase() + frequency.slice(1)}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

TaskFrequencyDropdown.propTypes = {
  defaultFrequency: PropTypes.oneOf(Object.values(TaskFrequency)),
  onSelect: PropTypes.func.isRequired,
}

export default TaskFrequencyDropdown
