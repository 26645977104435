import { useRef, useState } from 'react'

interface Props {
  content: React.ReactNode
  children: React.ReactNode
  className?: string
}

function Tooltip({ content, children, className }: Props) {
  const [hovered, setHovered] = useState(false)
  const tooltipRef = useRef<HTMLSpanElement>(null)
  const wrapperRef = useRef<HTMLSpanElement>(null)

  return (
    <span
      ref={wrapperRef}
      className="relative inline-block"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      {children}
      {hovered && (
        <span
          ref={tooltipRef}
          className={`absolute whitespace-nowrap px-3 py-1 bg-black text-white text-xs rounded shadow-lg bottom-full mb-2 left-1/2 transform -translate-x-1/2 ${className}`}
        >
          {content}
        </span>
      )}
    </span>
  )
}

export default Tooltip
