import { Chain } from '../types/constants'

export enum ChainId {
  MAINNET = 1,
  OPTIMISM = 10,
  POLYGON = 137,
  GNOSIS = 100,
  ARBITRUM = 42161,
  BSC = 56,
  AVALANCHE = 43114,
  BASE = 8453,
}

export const CHAINS: Record<ChainId, Chain> = {
  [ChainId.MAINNET]: {
    explorer: 'https://etherscan.io/',
    name: 'Ethereum',
    logoUrl: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=035',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: '#fff',
    id: ChainId.MAINNET,
    shortName: 'eth',
    rpc: 'https://eth.llamarpc.com',
    isTestnet: false,
  },

  [ChainId.OPTIMISM]: {
    explorer: 'https://optimistic.etherscan.io/',
    name: 'Optimism',
    logoUrl: 'https://cryptologos.cc/logos/optimism-ethereum-op-logo.svg?v=035',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: '#bb437e',
    id: ChainId.OPTIMISM,
    shortName: 'opt',
    rpc: 'https://endpoints.omniatech.io/v1/op/mainnet/public',
    isTestnet: false,
  },
  [ChainId.POLYGON]: {
    explorer: 'https://polygonscan.com/',
    name: 'Polygon',
    logoUrl: 'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=035',
    nativeCurrency: { name: 'Görli Ether', symbol: 'görETH', decimals: 18 },
    color: '#bb437e',
    id: ChainId.POLYGON,
    shortName: 'matic',
    rpc: 'https://polygon-rpc.com',
    isTestnet: false,
  },
  [ChainId.GNOSIS]: {
    explorer: 'https://gnosisscan.io/',
    name: 'Gnosis',
    logoUrl: 'https://cryptologos.cc/logos/gnosis-gno-gno-logo.svg?v=035',
    nativeCurrency: { name: 'xDAI', symbol: 'xDAI', decimals: 18 },
    color: '#bb437e',
    id: ChainId.GNOSIS,
    shortName: 'gnosis',
    rpc: 'https://rpc.gnosischain.com',
    isTestnet: false,
  },
  [ChainId.ARBITRUM]: {
    explorer: 'https://arbiscan.io/',
    name: 'Arbitrum',
    logoUrl: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=035',
    nativeCurrency: { name: 'Ether', symbol: 'ETH', decimals: 18 },
    color: '#bb437e',
    id: ChainId.ARBITRUM,
    shortName: 'arbitrum',
    rpc: 'https://arb1.arbitrum.io/rpc',
    isTestnet: false,
  },
  [ChainId.BSC]: {
    explorer: 'https://bscscan.com/',
    name: 'BNB Smart Chain',
    shortName: 'bnb',
    logoUrl: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=035',
    nativeCurrency: { name: 'BNB', symbol: 'BNB', decimals: 18 },
    color: '#bb437e',
    id: ChainId.BSC,
    isTestnet: false,
    rpc: 'https://rough-sleek-hill.bsc.quiknode.pro/413cc98cbc776cda8fdf1d0f47003583ff73d9bf',
  },
  [ChainId.AVALANCHE]: {
    explorer: 'https://snowtrace.io/',
    name: 'Avalanche',
    shortName: 'avalanche',
    logoUrl: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=035',
    nativeCurrency: { name: 'Avax', symbol: 'AVAX', decimals: 18 },
    color: '#bb437e',
    id: ChainId.AVALANCHE,
    isTestnet: false,
    rpc: 'https://rpc.ankr.com/avalanche',
  },
  [ChainId.BASE]: {
    explorer: 'https://basescan.org/',
    name: 'Base',
    shortName: 'base',
    logoUrl: 'https://avatars.githubusercontent.com/u/108554348?v=4',
    nativeCurrency: { name: 'ETH', symbol: 'ETH', decimals: 18 },
    color: '#bb437e',
    id: ChainId.BASE,
    isTestnet: false,
    rpc: 'https://developer-access-mainnet.base.org',
  },
}
