import {
  ArrowPathIcon,
  CheckCircleIcon,
  ClockIcon,
  ExclamationCircleIcon,
  QueueListIcon,
  TrashIcon,
  XCircleIcon,
} from '@heroicons/react/24/solid'

import Tooltip from '../Common/Tooltip'

interface Props {
  status: string
}

const statusIcon: Record<string, JSX.Element> = {
  completed: <CheckCircleIcon className="w-5 h-5 text-green-500" />,
  failed: <XCircleIcon className="w-5 h-5 text-red-500" />,
  processing: <ArrowPathIcon className="w-5 h-5 animate-spin text-blue-500" />,
  pending: <ClockIcon className="w-5 h-5 text-yellow-500" />,
  enqueued: <QueueListIcon className="w-6 h-6 text-blue-500" />,
  discarded: <TrashIcon className="w-6 h-6 text-gray-500" />,
  submitted: <ClockIcon className="w-6 h-6 text-yellow-500" />,
  succeeded: <CheckCircleIcon className="w-6 h-6 text-green-500" />,
  expired: <ExclamationCircleIcon className="w-6 h-6 text-orange-500" />,
}

export default function TaskStatusIcon({ status }: Props) {
  return (
    <Tooltip content={<span className="capitalize">{status}</span>}>
      {statusIcon[status] || <span className="text-gray-500">Unknown</span>}
    </Tooltip>
  )
}
