import type {
  AccountNonceResponse,
  AccountResponse,
  AccountsAuthRequest,
  AccountTokenBalancesResponse,
  AccountUpdateRequest,
  AccountVerifyResponse,
  ChainSettingResponse,
  ConversationsAppendRequest,
  IntentsResponse,
  MessageResponse,
  SettlersGetRequest,
  SettlersResponse,
  TasksGetRequest,
  TasksResponse,
} from '@mimic-fi/v4-backend-helpers'
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'

import { TaskPostRequest } from './types/hooks'

class API {
  private static prefixes: Record<string, string> = {
    beacon: '/beacon',
    bot: '/bot',
  }
  private static api: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  })

  static setInterceptors(
    onFulfilled: (value: AxiosResponse) => AxiosResponse,
    onRejected: (error: AxiosError) => unknown
  ) {
    return this.api.interceptors.response.use(onFulfilled, onRejected)
  }

  static removeInterceptors(interceptorId: number) {
    return this.api.interceptors.response.eject(interceptorId)
  }

  static setCommonHeaders(headers: Record<string, string>) {
    const commonHeaders = { ...this.api.defaults.headers.common }
    this.api.defaults.headers.common = {
      ...commonHeaders,
      ...headers,
    }
  }

  static removeCommonHeader(key: string) {
    delete this.api.defaults.headers.common[key]
  }

  static getAccount() {
    return this.api.get<AccountResponse>(`${this.prefixes.bot}/accounts/me`)
  }

  static getAccountNonce(params: { address: string }) {
    return this.api.post<AccountNonceResponse>(`${this.prefixes.bot}/accounts/nonce`, params)
  }

  static verifyAccount(data: AccountsAuthRequest) {
    return this.api.post<AccountVerifyResponse>(`${this.prefixes.bot}/accounts/verify`, data)
  }

  static getIntents(taskId: string) {
    return this.api.get<IntentsResponse>(`${this.prefixes.bot}/accounts/me/tasks/${taskId}/intents`)
  }

  static getSettlers(params: SettlersGetRequest) {
    return this.api.get<SettlersResponse>(`${this.prefixes.beacon}/settlers`, { params })
  }

  static getAccountChatMessages() {
    return this.api.get<MessageResponse[]>(`${this.prefixes.bot}/accounts/me/chat`)
  }

  static deleteAccountChat() {
    return this.api.delete(`${this.prefixes.bot}/accounts/me/chat`)
  }

  static postAccountChatMessage(data: ConversationsAppendRequest) {
    return this.api.post<MessageResponse>(`${this.prefixes.bot}/accounts/me/chat`, data)
  }

  static getChainSettings(chainId: number) {
    return this.api.get<ChainSettingResponse>(`${this.prefixes.bot}/chain-settings/${chainId}`)
  }

  static updateAccount(data?: AccountUpdateRequest) {
    return this.api.put<AccountResponse>(`${this.prefixes.bot}/accounts/me`, data)
  }

  static getAccountTasks(params?: TasksGetRequest) {
    return this.api.get<TasksResponse>(`${this.prefixes.bot}/accounts/me/tasks`, { params })
  }

  static postAccountTask(data: TaskPostRequest) {
    return this.api.post(`${this.prefixes.bot}/accounts/me/tasks`, data)
  }

  static getAccountTokens(chainId: number) {
    return this.api.get<AccountTokenBalancesResponse>(`${this.prefixes.bot}/accounts/me/tokens/${chainId}`)
  }
}

export default API
