import { NavLink } from 'react-router-dom'

import { ReactComponent as Success } from '../../assets/success.svg'

function SuccessResponse() {
  return (
    <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
      <Success className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Your task was created successfully.</h2>
      <p className="mt-2 text-lg">
        You can view all your tasks anytime by clicking{' '}
        <NavLink to="/tasks" className="text-primary">
          here
        </NavLink>{' '}
        to see details and progress.
      </p>
    </div>
  )
}

export default SuccessResponse
