import { useState } from 'react'

import { ReactComponent as Task } from '../assets/task.svg'
import useTasks from '../hooks/useTasks'
import { useWallet } from '../hooks/useWallet'
import { formatDate } from '../hooks/utils'

import Container from './Common/Container'
import AwaitingConnection from './EmptyStates/AwaitingConnection'
import NotConnected from './EmptyStates/NotConnected'
import Intents from './Tasks/Intents'
import TaskStatusIcon from './Tasks/TaskStatusIcon'
import AutoExit from './Templates/AutoExit'
import BlockTrade from './Templates/BlockTrade'
import Rebalancing from './Templates/Rebalancing'
import Chat from './Chat'

function Tasks() {
  const { isConnected, isConnecting } = useWallet()
  const { data, isLoading, error } = useTasks()
  const [selectedTaskId, setSelectedTaskId] = useState<string | null>(null)

  const option = localStorage.getItem('option')
  const renderComponent = () => {
    switch (option) {
      case 'chat':
        return <Chat />
      case 'rebalancing':
        return <Rebalancing />
      case 'autoExit':
        return <AutoExit />
      case 'blockTrade':
        return <BlockTrade />
      default:
        return <Chat />
    }
  }

  const handleTaskClick = (taskId: string) => {
    setSelectedTaskId(selectedTaskId === taskId ? null : taskId)
  }

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-4 pt-20 md:pt-0 md:pl-20 p-4">
      {!isConnected ? (
        isConnecting ? (
          <AwaitingConnection />
        ) : (
          <NotConnected />
        )
      ) : (
        <>
          <Container
            className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto p-4 min-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)] box-border"
            isLoading={isLoading}
            error={error?.message}
          >
            {data?.length === 0 ? (
              <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
                <Task className="h-[70px] w-[70px] mb-4" />
                <h2 className="text-xl font-semibold">You haven’t created any tasks yet.</h2>
                <p className="mt-2 text-lg text-center">
                  You can start by chatting or following a template to create one.
                </p>
              </div>
            ) : (
              <div className="space-y-4 p-1 md:p-4">
                <h2 className="text-xl font-semibold">My tasks</h2>
                {data?.map((task) => (
                  <div key={task.id} className="space-y-2 ">
                    <div
                      className="flex flex-col p-6 bg-backgroundAvobeSurface rounded-xl mb-4 shadow-lg transition-shadow duration-300 hover:shadow-xl cursor-pointer"
                      onClick={() => handleTaskClick(task.id)}
                    >
                      <div className="flex flex-col md:flex-row items-start md:items-center justify-between mb-4">
                        <div className="flex items-center gap-2">
                          <span className="text-lg text-whiteFrost font-semibold">{task.description}</span>
                        </div>
                        <div className="flex items-center">
                          <span className="text-sm font-semibold text-gray-400 mr-2">Status:</span>
                          <TaskStatusIcon status={task.status} />
                        </div>
                      </div>
                      <div className="grid grid-cols-1 md:grid-cols-4 gap-4 text-gray-400 text-sm">
                        <div className="flex flex-row md:flex-col gap-2 md:gap-0">
                          <span className="font-semibold text-whiteFrost">Frequency</span>
                          <span>{task.frequency}</span>
                        </div>
                        <div className="flex flex-row md:flex-col gap-2 md:gap-0">
                          <span className="font-semibold text-whiteFrost">Interval</span>
                          <span>{task.interval}</span>
                        </div>
                        <div className="flex flex-row md:flex-col gap-2 md:gap-0">
                          <span className="font-semibold text-whiteFrost">Last Execution</span>
                          <span>{task.lastExecutionDate ? formatDate(task.lastExecutionDate) : 'None'}</span>
                        </div>
                        <div className="flex flex-row md:flex-col gap-2 md:gap-0">
                          <span className="font-semibold text-whiteFrost">Next Execution</span>
                          <span>{task.nextExecutionDate ? formatDate(task.nextExecutionDate) : 'None'}</span>
                        </div>
                      </div>
                    </div>
                    {selectedTaskId === task.id && (
                      <div
                        className="mx-5 mb-5 p-4 bg-backgroundBelow rounded-b-lg overflow-x-auto"
                        style={{ position: 'relative', top: '-16px' }}
                      >
                        <Intents taskId={task.id} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </Container>
          <div className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto p-4 min-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)] overflow-y-auto">
            {renderComponent()}
          </div>
        </>
      )}
    </div>
  )
}

export default Tasks
