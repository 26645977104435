import React from 'react'
import classNames from 'classnames'

interface Option {
  value: string | number
  label: string
}

interface Props {
  name: string
  value?: string | number
  label?: string
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void
  options: Option[]
  placeholder?: string
  className?: string
}

export function Dropdown({ name, value, onChange, options, placeholder, className }: Props) {
  return (
    <select
      name={name}
      value={value ?? ''}
      onChange={onChange}
      className={classNames(
        'bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm cursor-pointer focus:outline-none appearance-none',
        className
      )}
      style={{
        WebkitAppearance: 'none',
        MozAppearance: 'none',
        appearance: 'none',
      }}
    >
      {placeholder && (
        <option value="" className="text-gray-500">
          {placeholder}
        </option>
      )}
      {options.map((option, i) => (
        <option
          key={`${option.value}-${i}`}
          value={option.value}
          className="bg-backgroundAvobeSurface text-whiteFrost text-xs"
        >
          {option.label}
        </option>
      ))}
    </select>
  )
}
