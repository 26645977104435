export enum Mode {
  Signer,
  From,
  To,
  SourceChain,
  DestinationChain,
  TokensIn,
  AmountsIn,
  TokensOut,
  MinAmountsOut,
}

export enum ListType {
  Allow,
  Deny,
}

export type Domain = {
  name: 'Mimic'
  version: string
  verifyingContract: `0x${string}`
}

export type Types = {
  Hooks: [
    { name: 'init'; type: 'Hook[]' },
    { name: 'pre'; type: 'Hook[]' },
    { name: 'post'; type: 'Hook[]' },
    { name: 'end'; type: 'Hook[]' },
  ]
  Hook: [{ name: 'id'; type: 'bytes32' }, { name: 'config'; type: 'bytes' }]
}

export type Hook = { id: string; config: string }

export type Hooks = {
  init: Hook[]
  pre: never[]
  post: never[]
  end: never[]
}

export type HooksMessage = {
  domain: Domain
  types: Types
  hooks: Hooks
}

export type TaskPostRequest = {
  description: string
  frequency: string
  interval: number
}
