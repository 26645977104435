import { Check, Loader2, X } from 'lucide-react'
import { isAddress } from 'viem'

import { ReactComponent as Coins } from '../assets/coins.svg'
import tokenImage from '../assets/token.png'
import useAllowance from '../hooks/useAllowance'
import useTokens from '../hooks/useTokens'
import { useWallet } from '../hooks/useWallet'

import Container from './Common/Container'
import AwaitingConnection from './EmptyStates/AwaitingConnection'
import NotConnected from './EmptyStates/NotConnected'
import AutoExit from './Templates/AutoExit'
import BlockTrade from './Templates/BlockTrade'
import Rebalancing from './Templates/Rebalancing'
import Chat from './Chat'

function Assets() {
  const { isConnected, isConnecting, chainId } = useWallet()
  const { data: tokens, isLoading, refetch: refetchTokens, error } = useTokens()

  const option = localStorage.getItem('option')
  const renderComponent = () => {
    switch (option) {
      case 'chat':
        return <Chat />
      case 'rebalancing':
        return <Rebalancing />
      case 'autoExit':
        return <AutoExit />
      case 'blockTrade':
        return <BlockTrade />
      default:
        return <Chat />
    }
  }

  const {
    handleAllowance,
    isApproved,
    formatAllowance,
    isLoading: isAllowanceLoading,
  } = useAllowance({
    chainId,
    refetchTokens,
  })

  const sortedTokens = tokens?.slice().sort((a, b) => {
    const valueA = (a.amount ?? 0) * (a.price ?? 0)
    const valueB = (b.amount ?? 0) * (b.price ?? 0)
    return valueB - valueA
  })

  return (
    <div className="w-full h-full flex flex-col md:flex-row gap-4 pt-20 md:pt-0 md:pl-20 p-4">
      {!isConnected ? (
        isConnecting ? (
          <AwaitingConnection />
        ) : (
          <NotConnected />
        )
      ) : (
        <>
          <Container
            className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto p-4 min-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)]"
            isLoading={isLoading}
            error={error?.message}
          >
            {sortedTokens?.length === 0 ? (
              <div className="flex-grow flex flex-col w-full h-[70vh] rounded-lg p-6 overflow-y-auto mb-6 items-center justify-center text-whiteFrost">
                <Coins className="h-[70px] w-[70px] mb-4" />
                <h2 className="text-2xl font-semibold">You don&apos;t have any assets right now.</h2>
                <p className="mt-2 text-lg">Make sure to transfer some to your wallet to get started.</p>
              </div>
            ) : (
              <div className="space-y-4 p-1 md:p-4 max-h-[calc(100vh-120px)] overflow-y-auto">
                <h2 className="text-xl font-semibold">My assets</h2>
                {sortedTokens
                  ?.filter((token) => (token.amount ?? 0) * (token.price ?? 0) >= 0.001)
                  .map((token) => (
                    <div
                      key={token.id}
                      className="flex justify-between items-center p-4 bg-backgroundAvobeSurface rounded-lg"
                    >
                      <div className="flex items-center gap-4">
                        {token.logo_url ? (
                          <img src={token.logo_url} alt={token.symbol} className="w-12 h-12" />
                        ) : (
                          <img src={tokenImage} alt={token.name} className="object-contain w-12 h-12 rounded-full" />
                        )}
                        <div className="flex flex-col">
                          <span className="text-lg text-whiteFrost font-bold">
                            {token.amount} {token.symbol}
                          </span>
                          <span className="text-sm text-whiteFrost">
                            Value: ${((token.amount ?? 0) * (token.price ?? 0)).toFixed(2)}
                          </span>
                          <span className="text-sm text-whiteFrost">Token Price: ${(token.price ?? 0).toFixed(2)}</span>
                        </div>
                      </div>
                      <div className="flex flex-col items-end">
                        {isAddress(token.id) && (
                          <>
                            <span className="text-sm text-whiteFrost">
                              Allowance: {formatAllowance(token.allowance || '', token.decimals)}
                            </span>
                            <button
                              className={`group mt-2 px-3 py-1 text-xs font-semibold text-whiteFrost border border-whiteFrost rounded hover:bg-oceanGround hover:border-oceanGround transition-colors flex items-center justify-center min-w-[100px] ${
                                isAllowanceLoading ? 'opacity-50 cursor-not-allowed' : ''
                              }`}
                              onClick={
                                isApproved(token.allowance)
                                  ? () => handleAllowance(token.id, true)
                                  : () => handleAllowance(token.id)
                              }
                              disabled={isAllowanceLoading}
                            >
                              {isAllowanceLoading ? (
                                <>
                                  <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                  {isApproved(token.allowance) ? 'Revoking...' : 'Approving...'}
                                </>
                              ) : (
                                <>
                                  {isApproved(token.allowance) ? (
                                    <>
                                      <div className="flex gap-1 group-hover:hidden">
                                        <Check className="h-4 w-4" />
                                        <span>Approved</span>
                                      </div>
                                      <div className="flex gap-1 hidden group-hover:flex">
                                        <X className="h-4 w-4" />
                                        <span>Revoke</span>
                                      </div>
                                    </>
                                  ) : (
                                    'Allow Mimic'
                                  )}
                                </>
                              )}
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            )}
          </Container>
          <div className="flex-1 bg-backgroundSurface rounded-[20px] overflow-auto p-4 min-h-[calc(100vh-120px)] max-h-[calc(100vh-120px)] overflow-y-auto">
            {renderComponent()}
          </div>
        </>
      )}
    </div>
  )
}

export default Assets
