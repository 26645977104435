import classNames from 'classnames'

import ErrorMessage from './ErrorMessage'
import Spinner from './Spinner'

interface Props {
  children: React.ReactNode
  isLoading?: boolean
  error?: string
  wide?: boolean
  className?: string
}

function Container({ children, isLoading, error, wide, className }: Props) {
  if (isLoading) {
    return (
      <div className={classNames('flex justify-center items-center h-full', className)}>
        <Spinner size="lg" />
      </div>
    )
  }

  if (error) {
    return (
      <div className={classNames('flex justify-center', className)}>
        <ErrorMessage message={error} />
      </div>
    )
  }
  return <div className={classNames('w-full', { 'xl:px-6': !wide }, className)}>{children}</div>
}

export default Container
