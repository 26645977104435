import classNames from 'classnames'

import useToken from '../../hooks/useToken'
import { formatTokenAmount, getTokenLogo } from '../../utils'

import Tooltip from './Tooltip'

interface Props {
  chainId: number
  address: string
  size?: number
  className?: string
  amount?: string
}

function Token({ chainId, address, size = 24, className, amount }: Props) {
  function shortAddress(address: string) {
    if (address.length <= 10) {
      return address
    }
    return address.substring(0, 5) + '...' + address.substring(address.length - 5)
  }

  const { data: token } = useToken(chainId, address)
  if (!token) return <>{shortAddress(address)}</>

  const logoURI = getTokenLogo(chainId, address)

  const sizeStyle = {
    width: `${size}px`,
    height: `${size}px`,
  }

  return (
    <div className="flex items-center justify-start">
      <Tooltip content={`${token.name} (${token.symbol})`}>
        <div
          className={classNames('flex items-center justify-center rounded overflow-hidden', className)}
          style={sizeStyle}
        >
          {logoURI ? <img src={logoURI} alt={token.name} className="object-contain" style={sizeStyle} /> : ''}
        </div>
      </Tooltip>
      {amount && <span>{formatTokenAmount(amount, token.decimals)}</span>}
    </div>
  )
}

export default Token
