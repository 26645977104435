import React, { createContext, useCallback, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { useAccount, useDisconnect, useSignMessage } from 'wagmi'

import API from '../api'

interface AuthContextProps {
  isAuthenticated: boolean
  isAuthenticating: boolean
  authenticate: () => Promise<void>
}

export const AuthContext = createContext<AuthContextProps>({
  isAuthenticated: false,
  isAuthenticating: false,
  authenticate: async () => {},
})

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const { address, isConnected } = useAccount()
  const { disconnect } = useDisconnect()
  const { signMessageAsync } = useSignMessage()
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isAuthenticating, setIsAuthenticating] = useState(false)
  const [checkedStorage, setCheckedStorage] = useState(false)

  const handleUnauthorized = useCallback(() => {
    setIsAuthenticated(false)
    localStorage.removeItem('authToken')
    API.removeCommonHeader('x-auth-token')
  }, [])

  const validateToken = useCallback(async () => {
    try {
      setIsAuthenticating(true)
      await API.getAccount()
      setIsAuthenticated(true)
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.status === 401) {
        handleUnauthorized()
      } else {
        console.error('Error validating token', error)
      }
    } finally {
      setIsAuthenticating(false)
    }
  }, [handleUnauthorized])

  useEffect(() => {
    const interceptor = API.setInterceptors(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          handleUnauthorized()
        }
        return Promise.reject(error)
      }
    )

    return () => {
      API.removeInterceptors(interceptor)
    }
  }, [handleUnauthorized])

  useEffect(() => {
    const checkStorage = async () => {
      const storedToken = localStorage.getItem('authToken')
      if (storedToken) {
        API.setCommonHeaders({ 'x-auth-token': storedToken })
        await validateToken()
      }
      setCheckedStorage(true)
    }

    if (address) {
      checkStorage()
    }
  }, [validateToken, address])

  useEffect(() => {
    const previousAddress = localStorage.getItem('previousAddress')
    if (address && previousAddress && previousAddress !== address) {
      handleUnauthorized()
    }
  }, [address])

  const authenticate = useCallback(async () => {
    if (!address) return

    setIsAuthenticating(true)
    try {
      const {
        data: { nonce },
      } = await API.getAccountNonce({ address })

      const signature = await signMessageAsync({
        message: `Sign in with nonce: ${nonce}`,
      })

      const {
        data: { token },
      } = await API.verifyAccount({ address, signature })

      localStorage.setItem('authToken', token)
      API.setCommonHeaders({ 'x-auth-token': token })
      setIsAuthenticated(true)
      localStorage.setItem('previousAddress', address)
    } catch (error) {
      console.error('Error during authentication', error)
      disconnect()
      setIsAuthenticated(false)
    } finally {
      setIsAuthenticating(false)
    }
  }, [address, signMessageAsync, disconnect])

  useEffect(() => {
    if (checkedStorage && isConnected && !isAuthenticated && !isAuthenticating) {
      authenticate()
    }
  }, [checkedStorage, isConnected, isAuthenticated, isAuthenticating])

  return (
    <AuthContext.Provider value={{ isAuthenticated, isAuthenticating, authenticate }}>{children}</AuthContext.Provider>
  )
}
