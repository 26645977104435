import { Chain, getDefaultConfig } from '@rainbow-me/rainbowkit'
import { arbitrum, aurora, avalanche, base, bsc, fantom, gnosis, mainnet, optimism, polygon } from 'wagmi/chains'

const customAurora: Chain = {
  ...aurora,
  iconUrl: 'https://avatars.githubusercontent.com/u/79027550?s=200&v=4',
}

const customFantom: Chain = {
  ...fantom,
  iconUrl: 'https://cryptologos.cc/logos/fantom-ftm-logo.svg?v=035',
}

export const wagmiConfig = getDefaultConfig({
  appName: 'Mimic V4 Prompt',
  // Every dApp that relies on WalletConnect needs a projectId from WalletConnect Cloud
  projectId: process.env.REACT_APP_PROJECT_ID || '',
  chains: [arbitrum, customAurora, avalanche, base, bsc, customFantom, gnosis, mainnet, optimism, polygon],
})

export const AVAILABLE_CHAIN_IDS: number[] = wagmiConfig.chains.map((chain) => chain.id)
