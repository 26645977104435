import { BigNumber, ethers } from 'ethers'

import { ChainId, CHAINS } from './constants/chains'
import { TOKENS } from './constants/tokens'

export function isSupportedChain(chainId: number) {
  return Object.values(ChainId).includes(chainId)
}

export function getTokenLogo(chainId: ChainId, address: string): string {
  const logoURI = TOKENS[chainId].find((token) => token.address.toLowerCase() == address.toLowerCase())?.logoURI
  if (logoURI) return logoURI
  const chainName = CHAINS[chainId].name.toLowerCase()
  return `https://storage.googleapis.com/zapper-fi-assets/tokens/${chainName}/${address.toLowerCase()}.png`
}

export function formatTokenAmount(amount: string, decimals: number) {
  const amountBn = BigNumber.from(amount)
  const amountFloat = parseFloat(ethers.utils.formatUnits(amountBn, decimals))
  return amountFloat > 1_000_000_000
    ? amountFloat.toExponential(2)
    : amountFloat.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 4 })
}
