import { MIMIC_SIGNER } from '../../hooks/useSettings'

import AddressSection from './AddressSection'

function DelegationSettings() {
  return (
    <AddressSection
      field="signer"
      title="Delegate Signer"
      label="Who is allowed to sign intents on your behalf?"
      defaultAddress={MIMIC_SIGNER}
      defaultLabel={`${MIMIC_SIGNER.slice(0, 6)}...${MIMIC_SIGNER.slice(-4)} (Mimic)`}
    />
  )
}

export default DelegationSettings
