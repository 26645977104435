import { useState } from 'react'
import PropTypes from 'prop-types'

type Protocol = 'aave' | 'compound' | 'morpho'

interface ProtocolDropdownProps {
  defaultProtocol?: Protocol
  onSelect: (protocol: Protocol) => void
}

const protocols: Protocol[] = ['aave', 'compound', 'morpho']

const ProtocolDropdown: React.FC<ProtocolDropdownProps> = ({ defaultProtocol, onSelect }) => {
  const [selectedProtocol, setSelectedProtocol] = useState<Protocol | null>(defaultProtocol || null)
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const handleSelect = (protocol: Protocol) => {
    setSelectedProtocol(protocol)
    setDropdownOpen(false)
    onSelect(protocol)
  }

  return (
    <div className="relative flex flex-col">
      <label htmlFor="protocol" className="text-whiteFrost text-sm font-medium mb-1">
        Protocol
      </label>
      <input
        type="text"
        id="protocol"
        value={selectedProtocol || ''}
        readOnly
        onClick={() => setDropdownOpen(!isDropdownOpen)}
        placeholder="Select protocol"
        className="bg-backgroundAvobeSurface text-whiteFrost rounded-lg p-3 text-sm cursor-pointer"
      />
      {isDropdownOpen && (
        <div className="absolute left-0 right-0 mt-2 bg-backgroundBelow shadow-lg rounded-lg p-2 space-y-2 z-10">
          {protocols.map((protocol) => (
            <button
              key={protocol}
              onClick={() => handleSelect(protocol)}
              className="w-full text-left px-2 py-1 rounded-md hover:bg-primaryHover text-whiteFrost text-xs"
            >
              {protocol.charAt(0).toUpperCase() + protocol.slice(1)}
            </button>
          ))}
        </div>
      )}
    </div>
  )
}

ProtocolDropdown.propTypes = {
  defaultProtocol: PropTypes.oneOf(['aave', 'compound', 'morpho']),
  onSelect: PropTypes.func.isRequired,
}

export default ProtocolDropdown
