import { useFormContext } from 'react-hook-form'
import classNames from 'classnames'

import Input from '../Common/Input'

function MinAmountOutSettings() {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  const isSlippageError = !!errors.slippage
  const isExpirationError = !!errors.expiration
  const isError = isSlippageError || isExpirationError

  return (
    <div className="mt-4">
      <h2 className="text-sm font-medium text-whiteFrost mb-1">Slippage</h2>
      <div className="flex items-center flex-row flex-start">
        <div className="flex items-center">
          <Input
            type="number"
            step="0.01"
            min="0"
            max="50"
            {...register('slippage', {
              setValueAs: (value) => (value === '' ? undefined : parseFloat(value)),
            })}
            className={classNames('w-[60px] !px-0 mr-1', isSlippageError && 'border-red-500')}
            inputClassName="text-center w-full"
          />
          %
        </div>
        <div className="flex items-center pl-[5px] text-whiteFrost">
          with prices no older than
          <Input
            type="number"
            step="1"
            min="1"
            {...register('expiration', {
              setValueAs: (value) => (value === '' ? undefined : parseInt(value, 10)),
            })}
            className={classNames('w-[60px] !px-0 mx-2', isExpirationError && 'border-red-500')}
            inputClassName="text-center w-full"
          />
          <span>minutes</span>
        </div>
      </div>
      {isError && (
        <p className="text-red-500">
          {isSlippageError ? errors.slippage?.message?.toString() : errors.expiration?.message?.toString()}
        </p>
      )}
    </div>
  )
}

export default MinAmountOutSettings
