import { useFormContext } from 'react-hook-form'

import { CHAINS } from '../../constants/chains'
import { Chain } from '../../types/constants'
import { ListType } from '../../types/hooks'
import Checkbox from '../Common/Checkbox'
import { MultiSelect } from '../Common/MultiSelect'

const getOptionLabel = (option: Chain) => option.name
const getOptionValue = (option: Chain) => option.id

function ChainsSettings() {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const handleCheckChange = (checked: boolean, selectedType: ListType) => {
    if (checked) {
      setValue('destinationChains.type', selectedType)
    } else {
      setValue('destinationChains.type', undefined)
    }
  }

  return (
    <div className="flex justify-between items-center mt-4 gap-4">
      <MultiSelect
        label="Destination Chains"
        name="destinationChains.chains"
        control={control}
        options={Object.values(CHAINS)}
        getOptionLabel={getOptionLabel}
        getOptionValue={getOptionValue}
        placeholder="Select Chains"
      />
      <div className="flex gap-2 mt-[25px]">
        <Checkbox
          id="allowChains"
          checked={watch('destinationChains.type') === ListType.Allow}
          onChange={(checked) => handleCheckChange(checked, ListType.Allow)}
          label="Allow"
          isError={!!errors.destinationChains?.type}
        />
        <Checkbox
          id="exceptChains"
          checked={watch('destinationChains.type') === ListType.Deny}
          onChange={(checked) => handleCheckChange(checked, ListType.Deny)}
          label="Except"
          isError={!!errors.destinationChains?.type}
        />
      </div>
    </div>
  )
}

export default ChainsSettings
