'use client'

import React, { useEffect, useRef } from 'react'
import { X } from 'lucide-react'

interface ModalProps {
  isOpen: boolean
  onClose?: () => void
  title: string
  children: React.ReactNode
}

export default function Modal({ isOpen, onClose, title, children }: ModalProps) {
  const modalRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape' && onClose) {
        onClose()
      }
    }

    if (isOpen) {
      document.addEventListener('keydown', handleEscape)
      document.body.style.overflow = 'hidden'
    }

    return () => {
      document.removeEventListener('keydown', handleEscape)
      document.body.style.overflow = 'unset'
    }
  }, [isOpen, onClose])

  if (!isOpen) return <></>

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-oceanGround bg-opacity-75">
      <div
        ref={modalRef}
        className="w-full max-w-md bg-backgroundSurface rounded-lg shadow-xl"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-title"
      >
        <div className="flex items-center justify-between p-4 border-b border-backgroundAvobeSurface">
          <h2 id="modal-title" className="text-xl font-dmsansBold text-whiteFrost">
            {title}
          </h2>
          {onClose && (
            <button
              onClick={onClose}
              className="p-1 text-whiteFrost hover:text-primary transition-colors"
              aria-label="Close modal"
            >
              <X size={24} />
            </button>
          )}
        </div>
        <div className="p-4 max-h-[calc(100vh-10rem)] overflow-y-auto">{children}</div>
      </div>
    </div>
  )
}
