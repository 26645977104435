import { Token } from '../types/constants'

import { ChainId } from './chains'

export const TOKENS: Record<ChainId, Token[]> = {
  [ChainId.MAINNET]: [
    {
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
    },
    {
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
  ],
  [ChainId.OPTIMISM]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
    },
    {
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x0b2c639c533813f4aa9d7837caf62653d097ff85',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0x94b008aa00579c1307b0ef2c499ad98a8ce58e58',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0x4200000000000000000000000000000000000006',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
  ],
  [ChainId.ARBITRUM]: [
    {
      address: '0xfd086bc7cd5c481dcc9c85ebe478a1c0b69fcbb9',
      name: 'Tether',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0xaf88d065e77c8cc2239327c5edb3a432268e5831',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0xda10009cbd5d07dd0cecc66161fc93d7c9000da1',
      name: 'Dai',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x82af49447d8a07e3bd95bd0d56f35241523fbab1',
      name: 'WETH',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/ETH/logo.svg',
    },
  ],
  [ChainId.BSC]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Binance Coin',
      symbol: 'BNB',
      decimals: 18,
      logoURI: 'https://assets.coingecko.com/coins/images/825/standard/bnb-icon2_2x.png?1696501970',
    },
    {
      address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0x55d398326f99059ff775485246999027b3197955',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0x4db5a66e937a9f4473fa95b1caf1d1e1d62e29ea',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
  ],
  [ChainId.AVALANCHE]: [
    {
      address: '0x0000000000000000000000000000000000000000',
      name: 'Avalanche',
      symbol: 'AVAX',
      decimals: 18,
      logoURI:
        'https://assets.coingecko.com/coins/images/12559/standard/Avalanche_Circle_RedWhite_Trans.png?1696512369',
    },
    {
      address: '0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0x9702230a8ea53601f5cd2dc00fdbc13d4df4a8c7',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
  ],
  [ChainId.POLYGON]: [
    {
      address: '0x8f3cf7ad23cd3cadbd9735aff958023239c6a063',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x3c499c542cef5e3811e1192ce70d8cc03d5c3359',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0xc2132d05d31c914a87c6611c10748aeb04b58e8f',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0x7ceb23fd6bc0add59e62ac25578270cff1b9f619',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
  ],
  [ChainId.GNOSIS]: [
    {
      address: '0x44fa8e6f47987339850636f88629646662444217',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0xddafbb505ad214d7b80b1f830fccc89b60fb7a83',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0x4ecaba5870353805a9f068101a40e0f32ed605c6',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0x6a023ccd1ff6f2045c3309768ead9e68f978f6e1',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
  ],
  [ChainId.BASE]: [
    {
      address: '0x50c5725949a6f0c72e6c4a641f24049a917db0cb',
      name: 'Dai Stablecoin',
      symbol: 'DAI',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/DAI/logo.svg',
    },
    {
      address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913',
      name: 'USD Coin',
      symbol: 'USDC',
      decimals: 6,
      logoURI: 'https://ethereum-optimism.github.io/data/USDC/logo.png',
    },
    {
      address: '0xfde4c96c8593536e31f229ea8f37b2ada2699bb2',
      name: 'Tether USD',
      symbol: 'USDT',
      decimals: 6,
      logoURI: 'https://cryptologos.cc/logos/tether-usdt-logo.svg?v=035',
    },
    {
      address: '0x4200000000000000000000000000000000000006',
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      logoURI: 'https://ethereum-optimism.github.io/data/WETH/logo.png',
    },
  ],
}
