import { useEffect, useRef, useState } from 'react'

import { ReactComponent as Send } from '../assets/send.svg'
import { ReactComponent as Wallet } from '../assets/wallet.svg'
import { useChatHistory } from '../hooks/useChatHistory'
import { useResetChat } from '../hooks/useResetChat'
import { useSendMessage } from '../hooks/useSendMessage'
import { useWallet } from '../hooks/useWallet'

function Chat() {
  const [accountInput, setAccountInput] = useState<string>('')

  const { address, isConnecting } = useWallet()

  const { data: messages, isLoading: isLoadingHistory, isError: isErrorHistory, error: errorHistory } = useChatHistory()
  const sendMessageMutation = useSendMessage()
  const resetChatMutation = useResetChat()
  const chatContainerRef = useRef<HTMLDivElement>(null)

  const handleSend = () => {
    if (accountInput.trim()) {
      sendMessageMutation.mutate(accountInput)
      setAccountInput('')
    }
  }

  const isLoading = isLoadingHistory || sendMessageMutation.isPending
  const isError = isErrorHistory || sendMessageMutation.isError || resetChatMutation.isError

  useEffect(() => {
    if (isError) {
      if (errorHistory) {
        console.error(errorHistory)
      } else if (sendMessageMutation.error) {
        console.error(sendMessageMutation.error)
      } else if (resetChatMutation.error) {
        console.error(resetChatMutation.error)
      }
    }
  }, [isError, errorHistory, sendMessageMutation.error, resetChatMutation.error])

  // Set scroll position to the bottom immediately
  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight
    }
  }, [messages])

  return (
    <div className="flex flex-col h-full w-full items-center justify-center box-border p-4 sm:p-6 md:p-8">
      {!address ? (
        isConnecting ? (
          <AwaitingConnection />
        ) : (
          <NotConnected />
        )
      ) : (
        <>
          <div
            ref={chatContainerRef}
            className="flex-grow w-full max-w-3xl h-[70vh] rounded-lg bg-backgroundSurface text-black p-1 md:p-6 overflow-y-auto mb-6"
          >
            {messages?.map((message, index) => (
              <div key={index} className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4`}>
                <div
                  className={`relative text-sm px-4 py-3 rounded-2xl w-[420px] ${
                    message.role === 'user'
                      ? 'bg-whiteFrost text-oceanGround rounded-br-none'
                      : 'bg-midnightSky text-whiteFrost rounded-bl-none'
                  }`}
                >
                  {message.content.split('\n').map((line, index) => (
                    <p key={index} style={{ margin: '0 0 10px' }}>
                      {line}
                    </p>
                  ))}
                  <div className="flex items-center justify-end space-x-2 mt-2 text-xs">
                    {message.role === 'user' ? (
                      <span className="text-oceanGround">You</span>
                    ) : (
                      <span className="text-whiteFrost">Assistant</span>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {isError && (
              <div className="relative text-sm text-white px-4 py-3 rounded-2xl w-[420px] bg-[#b6235761] rounded-bl-none">
                An error occurred. Please try again later.
                <div className="flex items-center justify-end space-x-2 mt-2 text-gray-300 text-xs">
                  <span>Assistant</span>
                </div>
              </div>
            )}
          </div>
          <div className="flex w-full max-w-3xl items-center rounded-lg overflow-hidden">
            <input
              value={accountInput}
              onChange={(e) => setAccountInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault()
                  handleSend()
                }
              }}
              placeholder="Ask me something..."
              disabled={isLoading || !address}
              className="flex-1 px-3 text-lg bg-backgroundAvobeSurface border-none outline-none h-[50px] rounded-l-[1rem] text-whiteFrost placeholder:text-whiteFrost placeholder:text-xs"
            />
            <button
              onClick={() => resetChatMutation.mutate()}
              disabled={isLoading || !address}
              className="bg-backgroundAvobeSurface h-[50px] min-w-[20px] hover:text-danger hover:font-semibold flex items-center justify-center"
            >
              x
            </button>
            <button
              onClick={handleSend}
              disabled={isLoading || !accountInput.trim() || !address}
              className="transition duration-200 inline-block h-[50px] w-[80px] border-2 bg-primary border-primary rounded-r-[1rem] font-visbyBold text-[16px] text-oceanGround cursor-pointer transition-colors hover:bg-primaryHover hover:border-primaryHover flex items-center justify-center"
            >
              {sendMessageMutation.isPending ? (
                <span className="text-sm">Sending...</span>
              ) : (
                <>
                  <Send className="block lg:hidden h-[18px] w-[18px] text-oceanGround" />
                  <span className="hidden lg:block">Send</span>
                </>
              )}
            </button>
          </div>
        </>
      )}
    </div>
  )
}

function NotConnected() {
  return (
    <div className="flex-grow flex flex-col w-full max-w-3xl h-[70vh] rounded-lg bg-backgroundSurface text-black p-6 overflow-y-auto mb-6 items-center justify-center text-white">
      <Wallet className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Wallet not connected</h2>
      <p className="mt-2 text-lg">Please connect your wallet to start chatting.</p>
    </div>
  )
}

function AwaitingConnection() {
  return (
    <div className="flex-grow flex flex-col w-full max-w-3xl h-[70vh] rounded-lg bg-backgroundSurface text-black p-6 overflow-y-auto mb-6 items-center justify-center text-white">
      <Wallet className="h-[70px] w-[70px] mb-4" />
      <h2 className="text-2xl font-semibold">Awaiting connection...</h2>
      <p className="mt-2 text-lg">Hang tight! We&apos;re linking to your wallet.</p>
    </div>
  )
}

export default Chat
