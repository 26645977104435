import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi'

import Assets from './components/Assets'
import Header from './components/Header'
import NetworkModal from './components/NetworkModal'
import Safeguards from './components/Safeguards/Safeguards'
import Sidebar from './components/Structure/Sidebar'
import Tasks from './components/Tasks'
import Welcome from './components/Welcome'
import { AuthProvider } from './contexts/AuthContext'
import { wagmiConfig } from './wagmi'

import '@rainbow-me/rainbowkit/styles.css'

function App() {
  const queryClient = new QueryClient()
  return (
    <WagmiProvider config={wagmiConfig}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <AuthProvider>
            <Router>
              <div className="flex flex-col min-h-screen">
                <Header />
                <main className="flex-grow flex flex-col items-center justify-center bg-backgroundBelow text-white p-0 md:p-4 h-full">
                  <div className="w-full">
                    <Sidebar />
                    <Routes>
                      <Route path="/tasks" element={<Tasks />} />
                      <Route path="/assets" element={<Assets />} />
                      <Route path="/safeguards" element={<Safeguards />} />
                      <Route path="/" element={<Welcome />} />
                    </Routes>
                    <NetworkModal />
                  </div>
                </main>
              </div>
            </Router>
          </AuthProvider>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  )
}

export default App
