import { useMutation, useQueryClient } from '@tanstack/react-query'

import API from '../api'

import { useWallet } from './useWallet'

const sendMessage = async (prompt: string) => {
  const response = await API.postAccountChatMessage({ prompt })
  return response.data
}

export const useSendMessage = () => {
  const queryClient = useQueryClient()
  const { address } = useWallet()

  return useMutation({
    mutationFn: (message) => sendMessage(message),
    onSuccess: (reply) => {
      queryClient.setQueryData(['chatHistory', address], (old: (typeof reply)[] = []) => [...old, reply])
    },
    onMutate: async (newMessage: string) => {
      await queryClient.cancelQueries({ queryKey: ['chatHistory', address] })

      const previousMessages = queryClient.getQueryData(['chatHistory', address])

      queryClient.setQueryData(['chatHistory', address], (old: unknown[] = []) => [
        ...old,
        { role: 'user', content: newMessage },
      ])

      return { previousMessages }
    },
    onError: (error, newMessage, context) => {
      queryClient.setQueryData(['chatHistory', address], context?.previousMessages)
    },
  })
}
